import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";

const ManifestList = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [manifestList, setManifestList] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');
  const salesOrderRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("button clicked");
    fetchData();
  };

  

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    fetchData();
  }, [fromDate, toDate, selectedRole]);

  
  const fetchData = (startDate, endDate) => {
  const $table = $(salesOrderRef.current);
  if (!$table.length) return;

 
  if ($.fn.DataTable.isDataTable($table)) {
    $table.DataTable().clear().destroy();
  }

  // Initialize DataTable
  $table.DataTable({
    processing: true,
    serverSide: true,
    pageLength: 50,
    ajax: {
      url: `${config.API_URL}/manifest/list`,
      type: "POST",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      data: function (d) {
        return {
          ...d,
          draw: d.draw,
          start: d.start,
          length: d.length
        };
      },
      dataSrc: function (json) {
        if (json?.data && Array.isArray(json.data)) {
          setManifestList(json.data);
          return json.data;
        }
        return [];
      },
      error: function (xhr, status, error) {
        console.error("DataTable AJAX error:", xhr.status, xhr.statusText, error, xhr.responseText);
      },
    },
    language: {
      paginate: { first: "First", last: "Last", next: "Next", previous: "Previous" },
      search: "Search:",
    },
    columns: [
      { data: "id", width: "4%" },
      { data: "date", width: "10%" },
      {
        data: "manifest_id",
        width: "10%",
        className: "text-center",
        render: function (data, type, row) {
          return row.manifest_id
            ? `<a href="${BASE_URL}/manifest_list/ManifestPrint/${row.manifest_id}">${row.manifest_id}</a>`
            : "-";
        },
      },
      {
      data: "trip_id",
      width: "15%",
      render: function (data, type, row) {
        return data ? data : " "; // Show "-" if trip_id is null or empty
      }
    },

      { data: "driver", width: "15%" },
      { data: "route_number", width: "15%" },
      { data: "sales_person", width: "35%" },
      {
        data: "status",
        width: "10%",
        render: function (data) {
          return `<span class="btn btn-primary badge">${data || ""}</span>`;
        },
      },
      {
        data: null,
        width: "5%",
        render: function (data, type, row) {
          return !row.trip_id
            ? `<a class="viewbtn" href="${BASE_URL}/manifest_list/mark_delivery/${data.manifest_id}" id="mark_deliver">
                <i class="fa fa-edit"></i>
              </a>`
            : "";
        },
      },
    ],
    columnDefs: [{ targets: [], sortable: true }],
  });

  // Cleanup on unmount
  return () => {
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }
  };
};





  return (
    <div>
      <Header />
      <div class="breadcrumbs">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-12 col-sm-12"> 
        <Link to="/Dashboard"><i class="fa fa-home"></i></Link> &gt; 
        <Link to="/Dashboard">Dashboard</Link> &gt; 
        <Link to="/ManifestList">Dispatch & Deliveries</Link> &gt;
        <span class="last-crumb">Manifest List</span> 
      </div>
    </div>
  </div>
</div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">                
                <div className="verical-scrl-wrapper menifest-area" id="style-3" style={{width: '100%'}}>
                  <table className="table table-bordered dataTable resizable" id="manifestlisttable" ref={salesOrderRef}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>date</th>
                        <th>Manifest Number</th>
                        <th>Trip ID</th>
                        <th>Driver</th>
                        <th>Route</th>
                        <th>Sales Person</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManifestList;
