import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';
import Swal from 'sweetalert2';

const PushSage = () => {

	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const accessToken = localStorage.getItem('accessToken'); 
  	const [pushSage, setPushSage] = useState([]);
  	const [checkedItems, setCheckedItems] = useState({});
	  const [isSubmitting, setIsSubmitting] = useState(false);
  	useEffect(() => {
        if (accessToken === null) {
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }       

        loadPushSageList();
    }, [draw, start, length, accessToken]);

	const loadPushSageList = async () => {
		try {
			const data = await AccountService.fetchPushSageList(draw, start, length, accessToken);
			setPushSage(data);
		} catch (error) {
			console.error('Failed to load push sage list:', error);
		}
	};

	const submitMultiplePushToSage = async () => {
		setIsSubmitting(true);
		let val = [];
		$('.dataTableCheckbox:checked').each(function () {
		  val.push($(this).val());
		});
	  
		if (val.length === 0) {
		 // alert('Please select at least one invoice.');
		 
				 Swal.fire({
		  title: "Failed!",
		  text: "Please select at least one invoice.",
		  icon: "error",
		  showConfirmButton: true,  // Show the confirm button
		  confirmButtonText: "OK",  // Text for the button
		}).then((result) => {
		  // If the user clicks the 'OK' button
		  if (result.isConfirmed) {
		    console.log("User clicked OK");
		    // Perform actions after confirmation if needed
		  }
		});
		setIsSubmitting(false);
		return;
		}
		if (val.length > 3) {
		 // alert('Please select at least one invoice.');
		 Swal.fire({
				  title: "Failed!",
				  text: "Maximum 3 invoices Pushed one Time.",
				  icon: "error",
				  showConfirmButton: true,  // Show the confirm button
				  confirmButtonText: "OK",  // Text for the button
				}).then((result) => {
				  // If the user clicks the 'OK' button
				  if (result.isConfirmed) {
				    console.log("User clicked OK");
				    // Perform actions after confirmation if needed
				  }
				});

				setIsSubmitting(false);
				return;
		   
		}
	  
		const Url = `${config.API_URL}/bulk_push_invoice`;
		try {
		  const formData = new FormData();
		  formData.append('val', JSON.stringify(val)); 
	  
		  const response = await fetch(Url, {
			method: 'POST',
			headers: {
			  authorization: `Bearer ${accessToken}`,
			},
			body: formData,
		  });
	  
		  const data = await response.json();
		  if (data.success === true) {

		  	Swal.fire({
		          title: "Success!",
		          text: "Push Invoice successfully.",
		          icon: "success",
		          timer: 1500,
		          showConfirmButton: false,
		        }).then(() => 
		        {
		          loadPushSageList();
				  setIsSubmitting(false);
		        });
			
			//console.log('Push to sage performed successfully');
		  } else {
		  	 Swal.fire({
		          title: "Failed!",
		          text: "Failed to push to sage",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
			  setIsSubmitting(false);
			//console.log('Failed to push to sage');
		  }
		} catch (error) {
		  console.error('API request failed:', error);
		}
	  };
	  

  	useEffect(() => {
    	const table = $('#pushSageList').DataTable({
      		pageLength: 50,
      		paging: true,
      		lengthMenu: [10, 20, 50],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
        		paginate: {
          			first: 'First',
          			last: 'Last',
          			next: 'Next',
          			previous: 'Previous',
        		},
        		search: 'Search:',
      		},
      		data: pushSage,
      		columns: [
        		{
          			data: null,
          			className : 'text-center',
          			width : '4%',
         			render: function (data, type, row) { 
         				const isChecked = !!checkedItems[row.invoice_amount];
            			return `
			              <label class="checkbox" style="margin: 0px auto;float: none;">
			                <input 
			                  type="checkbox" 
			                  name="val[]" 
			                  value="${row.id}" 
			                  class="dataTableCheckbox"
			                />
			                <span class="default"></span>
			              </label>
			            `;
          			}
        		},
		        { data: 'invoice_date', className : 'text-start', width : '12%' },
		        { data: 'invoice_date', className : 'text-start', width : '12%' },
		        { 
		        	data: null, className : 'text-start', width : '12%',
		        	render: function (data, type, row) {
	                	return '<a href="'+BASE_URL+'/trip_summary/print/' + row.id + '">' + row.invoice_number + '</a>';
	            	}
		        },
		        { data: 'customer' },
		        { data: 'invoice_amount', className : 'text-end', width : '10%' },
				{
					data: null,
					render: function (data, type, row) {
					  return `
						<a href="#" class="viewbtn" data-id="${row.id}" tool-tip="Remove product">
						   <i class="fa fa-thumb-tack" style="width: 27px;height: 27px;padding: 5px 0px 0px 8px;" ></i>
						</a>`;
					},
					createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
					  $(cell).find('.viewbtn').on('click', function (e) {
						e.preventDefault();
						const id = $(this).data('id'); 
						pushToSage(id);
					  });
					}
				  }
      		],
      		columnDefs: [
		        
      		]
   	 	});

    

	    return () => {
	      table.destroy();
	    };
  	}, [pushSage]);

	const pushToSage = async (id) => {
		const Url = `${config.API_URL}/push_invoice`;
		try {
			const formData = new FormData();
			formData.append('id', id);
	
			const response = await fetch(Url, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			const data = await response.json();
			if (data.success === true) {
		  	Swal.fire({
		          title: "Success!",
		          text: "Push Invoice successfully.",
		          icon: "success",
		          timer: 1500,
		          showConfirmButton: false,
		        }).then(() => 
		        {
		          loadPushSageList();
		        	
		        });
			
			//console.log('Push to sage performed successfully');
		  } else {
		  	 Swal.fire({
		          title: "Failed!",
		          text: "Failed to push to sage",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
			//console.log('Failed to push to sage');
		  }
		} catch (error) {
			console.error('API request failed:', error);
		}
	};

  	return(
  		<>
  			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/push_sage">Account</Link> 
		                  <Link to="/account/push_sage">Push to Sage</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Push To Sage </b></p>
	                    
	                    <div className="verical-scrl-wrapper common-table autoheight-table pushsage-area" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="pushSageList">
	                            <thead>
	                                <tr>
	                                    <th>#</th>
	                                    <th>Proforma Inv Date</th>
	                                    <th>Delivery Date</th>
	                                    <th>Invoice Number</th>
	                                    <th>Customer Name</th>
	                                    <th>Invoice Amount</th>
	                                    <th>Action</th>

	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                            <tfoot>

					            </tfoot>
	                        </table>
	                    </div>
	                    <div className="col-2 text-start">
	                		<button type="botton" class="acceptbtn" style={{float: 'left', margin: '26px 0px 0px'}} onClick={submitMultiplePushToSage} disabled={isSubmitting} >Submit</button>
	                	</div>
	                </div>               

			    </div>
			</div>
	      	<Footer />

  		</>
  	);

}
export default PushSage;