import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from "../Config";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import config from "../common/Config";
import Multiselect from "./Multiselect";
import { Link } from "react-router-dom";
import Select from "react-select";

const Catalogue = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [brandList, setBrandList] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productsSelected, setProductsSelected] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productType, setProductType] = useState("");
  const [hideProducts, setHideProducts] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const accessToken = localStorage.getItem("accessToken");

  const fetchCatalogueList = async () => {
    const brandForm = new FormData();
    brandForm.append("draw", draw);
    brandForm.append("start", start);
    brandForm.append("length", length);

    const apiBrandUrl = `${config.API_URL}/ws-catalog-list`;
    try {
      const response = await fetch(apiBrandUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: brandForm,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch brand list");
      }

      const data = await response.json();
      // console.log(data);
      setBrandList(data.data);
    } catch (error) {
      console.log("Error fetch brand list", error);
    }
  };

  const fetchProductList = async () => {
    setProductOptions([]);
    const Url = `${config.API_URL}/get-product`;
    try {
      const response = await fetch(Url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.map((item) => ({
        label: item.name + "(" + item.code + ")",
        value: item.id,
      }));
      setProductOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching products");
    }
  };

  const fetchCategoryList = async () => {
    setProductOptions([]);
    const Url = `${config.API_URL}/get-category`;
    try {
      const response = await fetch(Url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProductOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching products category wise");
    }
  };

  const fetchBrandList = async () => {
    setProductOptions([]);
    const Url = `${config.API_URL}/get-brands`;
    try {
      const response = await fetch(Url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProductOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching products category wise");
    }
  };

  const fetchPromotedProductList = async () => {
    setProductOptions([]);
    const Url = `${config.API_URL}/get-promoted-product`;
    try {
      const response = await fetch(Url, {
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProductOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching products category wise");
    }
  };



  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      pageLength: 10,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: brandList,
      columns: [
      {
        data: null,
        className: "text-center",
        width: "4%",
        render: function (data, type, row, meta) {
          return meta.row + meta.settings._iDisplayStart + 1;
        },
      },
      { data: "date", className: "text-start", width: "10%" },
      { data: "ref_num", className: "text-start" },
      { data: "pdf_file", className: "text-start", width: "14%" },
      {
        data: "code",
        className: "text-center",
        width: "10%",
        render: function (data, type, row) {
          const checked = row.status == "1" ? "checked" : "";
          return `
          <label class="switch" style="margin: 0px auto;">
          <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
          <span class="switch-label" data-on="Active" data-off="Inactive"></span>
          <span class="switch-handle"></span>
          </label>`;
        },
      },
      {
        data: "code",
        className: "text-center",
        width: "10%",
        render: function (data, type, row) {
    // Check if the pdf_file is empty or null
          if (!row.pdf_file) {
      // If the pdf_file is null or empty, show a loading button
            return `
            <button class="btn btn-loading" disabled>
            <i class="fa fa-spinner fa-spin"></i> Loading...
            </button>
            `;
          } else {
      // Otherwise, show the download button
            const fileUrl = `${config.API_URL}/public/${row.pdf_file}`;
            return `
            <a href="${fileUrl}" target="_blank" download class="btn btn-download" title="Download PDF">
            <i class="fa fa-download"></i> Download
            </a>
            `;
          }
        }
      }
      ],
      columnDefs: [],
    });

    $("#neworderTable")
    .off("change", ".switch-input")
    .on("change", ".switch-input", function (event) {
      const id = $(this).data("id");
      const status = this.checked ? 1 : 0;

      handleChangeStatus(id, status);
    });

    $("#brandListTable")
    .off("click", ".edit-icon")
    .on("click", ".edit-icon", function (event) {
      const rowId = $(this).data("id");
      const rowData = table.row($(this).closest("tr")).data();

      if (rowData && Object.keys(rowData).length > 0) {
        console.log(rowData);
          // handleEditClick(rowData);
      }
    });

    return () => {
      table.destroy();
    };
  }, [brandList]);

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    


    fetchCatalogueList();
  }, [accessToken]);

  useEffect(() => {
    setHideProducts(true);
    if(productType === 'products') {
      fetchProductList();
    }
    else if(productType === 'categories'){
      fetchCategoryList();
    }
    else if(productType === 'brands'){
      fetchBrandList();
    }
    else if(productType === 'new_arrivals'){
      setProductOptions([]);
      setHideProducts(false);
    }
    else if(productType === 'promoted'){
      fetchPromotedProductList();
    }
    

    // fetchCatalogueList();
  }, [productType]);

  const changeProductType = (e) => {
    const value = e.target.value; // Get the selected value
    setProductType(value); // Update the state
  };

  const specialOptions = [
    { label: "Select All", value: "select_all" },
    { label: "Remove All", value: "remove_all" },
    ];

  // Handle selection change
  const handleChange = (selected) => {
    // Ensure selected is always an array
    const selectedOptions = selected || [];
    
    // Check if the last selected option is "Select All" or "Remove All"
    const lastSelected = selectedOptions[selectedOptions.length - 1];
    
    if (lastSelected?.value === "select_all") {
      setSelectedOptions(productOptions);
      setProductsSelected(productOptions);
    } else if (lastSelected?.value === "remove_all") {
      setSelectedOptions([]);
      setProductsSelected([]);
    } else {
      setSelectedOptions(selectedOptions);
      setProductsSelected(selectedOptions);
    }
  };

  const submitCatalogue = async () => {
    setIsSubmitting(true);
    const productValues = productsSelected.map(product => product.value);
    const form = document.getElementById('createCatalogue'); 
    const formdata = new FormData(form); 
    formdata.append('productlist', productValues);
    const Url = `${config.API_URL}/create-catalogue`;

  // Show success message immediately after hitting the API
    Swal.fire({
      title: "Success!",
      text: "Please wait! Your Product Catalogue is being generated.",
      icon: "success",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
      // You can choose to reload or handle other UI changes when the user confirms
        window.location.reload();
      }
    });

    try {
    // Make the API request in the background
      const response = await fetch(Url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formdata, 
      });

    // Process the response data if needed
      const data = await response.json();
      console.log(data);
      
    // You can perform any further actions based on the response here

    } catch (error) {
      console.error("API failed when submitting catalogue:", error);
    // In case of error, show an error alert
      Swal.fire({
        title: "Error",
        text: "There was an error submitting the catalogue. Please try again later.",
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
    setIsSubmitting(false); // Re-enable the button
  }
};

const handleChangeStatus = async(id, status)=>{
  const formData = new FormData();
  formData.append('id', id);
  formData.append('status', status);
  const apiUrl = `${config.API_URL}/update_catalogs_status`; 
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
       authorization : `Bearer ${accessToken}`,
     },
     body: formData,
   });

    if (!response.ok) {
      throw new Error('Failed to change status');
    }

    const data = await response.json();
    console.log(data);
    
    Swal.fire({
      title: 'Success',
      text: 'Status changed successfully.',
      icon: 'success', 
      showConfirmButton: false, 
      timer: 1500,  
    });

    fetchCatalogueList();

    
  } catch (error) {
    console.error('Error status change:', error);
  }
}
return (
  <div>
  <div class="modal fade" id="create-catalogue" tabindex="-1">
  <div class="modal-dialog modal-lg">
  <div class="modal-content">
  <div class="modal-header">
  <h5 class="modal-title">Create Catalogue</h5>
  <button
  type="button"
  class="btn-close"
  data-bs-dismiss="modal"
  aria-label="Close"
  ></button>
  </div>
  <div class="modal-body">
  <div class="catalogue-popup">
  <form id="createCatalogue">
  <div className="row mb-3 padd-l-13px">
  <label htmlFor="desc" className="col-sm-3 col-form-label">
  Product Type *:
  </label>
  <div className="col-sm-9">
  <ul>
  <li>
  <input
  type="radio"
  name="option"
  value="products"
                            checked={productType === "products"} // Check if the current value is selected
                            onChange={changeProductType}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="gridRadios1"
                            >
                            Products Wise
                            </label>
                            </li>
                            <li>
                            <input
                            type="radio"
                            name="option"
                            value="categories"
                            checked={productType === "categories"} // Check if the current value is selected
                            onChange={changeProductType}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="gridRadios2"
                            >
                            Categories Wise
                            </label>
                            </li>
                            <li>
                            <input
                            type="radio"
                            name="option"
                            value="brands"
                            checked={productType === "brands"} // Check if the current value is selected
                            onChange={changeProductType}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="gridRadios3"
                            >
                            Brands Wise
                            </label>
                            </li>
                            <li>
                            <input
                            type="radio"
                            name="option"
                            value="promoted"
                            checked={productType === "promoted"} // Check if the current value is selected
                            onChange={changeProductType}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="gridRadios4"
                            >
                            Promoted Wise
                            </label>
                            </li>
                            <li>
                            <input
                            type="radio"
                            name="option"
                            value="new_arrivals"
                            checked={productType === "new_arrivals"} // Check if the current value is selected
                            onChange={changeProductType}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="gridRadios5"
                            >
                            New Arrivals
                            </label>
                            </li>
                            </ul>
                            </div>
                            </div>
                            <hr />
                            <div class="row mb-3 padd-l-13px">
                            <label for="desc" class="col-sm-3">
                            &nbsp;
                            </label>
                            <div class="col-sm-9">
                            <div>
                            <label class="checkbox">
                            <input type="checkbox" name="packsize" />
                            <span class="default"></span>
                            </label>
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            Pack size{" "}
                            </label>
                            </div>

                            <div>
                            <label class="checkbox">
                            <input type="checkbox" name="barcode" />
                            <span class="default"></span>
                            </label>
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            Barcode
                            </label>
                            </div>
                            </div>
                            </div>
                            <hr />
                            <div class="row mb-3 padd-l-13px">
                            <label for="desc" class="col-sm-3 col-form-label">
                            Price :
                            </label>
                            <div class="col-sm-9">
                            <ul>
                            <li>
                            <input
                            class=""
                            type="radio"
                            name="type_price"
                            value="box_price"
                            />
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            Box Price{" "}
                            </label>
                            </li>
                            <li>
                            <input
                            class=""
                            type="radio"
                            name="type_price"
                            value="piece_price"
                            />
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            Piece Price{" "}
                            </label>
                            </li>
                            <li>
                            <input
                            class=""
                            type="radio"
                            name="type_price"
                            value="both"
                            />
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            Both{" "}
                            </label>
                            </li>
                            </ul>
                            </div>
                            </div>
                            <hr />
                            <div class="row mb-3 padd-l-13px">
                            <label for="desc" class="col-sm-3 col-form-label">
                            Size :
                            </label>
                            <div class="col-sm-9">
                            <ul>
                            <li>
                            <input
                            class=""
                            type="radio"
                            name="size_options"
                            value="1"
                            />
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            2 X 3{" "}
                            </label>
                            </li>
                            <li>
                            <input
                            class=""
                            type="radio"
                            name="size_options"
                            value="2"
                            />
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            3 X 3{" "}
                            </label>
                            </li>
                            <li>
                            <input
                            class=""
                            type="radio"
                            name="size_options"
                            value="3"
                            />
                            <label class="form-check-label" for="gridRadios1">
                            {" "}
                            3 X 4{" "}
                            </label>
                            </li>
                            </ul>
                            </div>
                            </div>

                            <div class="row mb-3 padd-l-13px">
                            <label for="desc" class="col-sm-3 col-form-label">
                            Front Page (A4):
                            </label>
                            <div class="col-sm-9">
                            <input class="form-control" type="file" id="formFile" name="banner_image" />
                            </div>
                            </div>

                            <div class="row mb-3 padd-l-13px">
                            <label for="desc" class="col-sm-3 col-form-label"> Catalogue Date: </label>
                            <div class="col-sm-9">
                            <input type="date" name="date" class="form-control"  />
                            </div>
                            </div>

                            {hideProducts && <div class="row mb-3 padd-l-13px">
                            <label for="desc" class="col-sm-3 col-form-label">
                            Select Product *
                            </label>
                    {/* <Multiselect productOptions={productOptions} setProductsSelected={setProductsSelected} /> */}
                            <Select isMulti
                            options={[...specialOptions, ...productOptions]}
                            value={selectedOptions}
                            onChange={handleChange}
                            closeMenuOnSelect={false}
                            />
                            </div>
                          }

                          <hr />
                          <div class="form-group">
                          <button type="button" id="submit" class="float-right-btn common-popup-btn" onClick={submitCatalogue} disabled={isSubmitting}>

                          Create Catalogue
                          </button>
                          </div>
                          </form>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>

                          <Header />
                          <nav className="breadcrumbs">
                          <div className="container-fluid">
                          <div className="row">
                          <div className="col-12 col-lg-12 col-sm-12">
                          <a href="#">
                          <i className="fa fa-home"></i>{" "}
                          </a>
                          <span className="last-crumb">Catalogue</span>
                          </div>
                          </div>
                          </div>
                          </nav>
                          <div className="main-container">
                          <div className="container-fluid">
                          <div className="row">
                          <div className="col-12 col-lg-12 col-sm-12">
                          <div style={{ float: "right" }}>
                          <button
                          type="button"
                          class="create-catalogue-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#create-catalogue"
                          
                          >
                          Create Catalogue
                          </button>
                          </div>

                          <div
                          className="show-order-area dashboard"
                          style={{ position: "relative" }}
                          >
                          <div
                          class="verical-scrl-wrapper common-table catalogue-tbl"
                          id="style-3"
                          >
                          <table
                          class="table table-bordered dataTable resizable"
                          id="neworderTable"
                          >
                          <thead>
                          <tr>
                          <th>Sr No.</th>
                          <th>Date</th>
                          <th>Reference No</th>
                          <th>Catalogue Name</th>
                          <th>Status</th>
                          <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {Array.from({ length: 2 }).map((_, index) => (
                          <tr key={index}>
                          <td style={{ width: "50px" }}>
                          <label class="checkbox">
                          <input type="checkbox" />
                          <span class="default"></span>
                          </label>
                          </td>

                          <td>WSHORTBREAD</td>
                          <td>Walkers Shortbread</td>
                          <td>Walkers Shortbread</td>
                          <td>
                          <a href="#">
                          <span class="label label-success">
                          <i class="fa fa-check"></i> Active
                          </span>
                          </a>
                          </td>
                          <td style={{ width: "120px" }}>
                          <a
                          href="#"
                          class="icon"
                          title="Edit"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-brand"
                          >
                          <i class="fa fa-pencil"></i>
                          </a>
                          <a href="#" class="icon" title="Delete">
                          <i class="fa fa-trash"></i>
                          </a>
                          </td>
                          </tr>
                          ))}
                          </tbody>
                          </table>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          <Footer />
                          </div>
                          );
                        };

                        export default Catalogue;
