import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { API_URL } from "./Config";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useParams } from "react-router-dom";
import { format } from "date-fns"; // Import format function from date-fns
import config from "./common/Config";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { prefix, userId } = useParams();

  const [dates, setDates] = useState([null, null]);
  const [selectedRole, setSelectedRole] = useState("");
  const [statisticsData, setStatisticsData] = useState(null); 

  const handleDateChange = (value) => {
    let dateRange = ''; 
    setDates(value);
    localStorage.setItem(dateRange, value);

  if (value && value[0] && value[1]) {
    const formattedStartDate = format(value[0], 'MM/dd/yyyy'); 
    const formattedEndDate = format(value[1], 'MM/dd/yyyy');

    const s1 = value[0] ? format(value[0], 'yyyy-MM-dd') : '';
    const e1 = value[1] ? format(value[1], 'yyyy-MM-dd') : '';  
    
    localStorage.setItem('startDate', s1);
    localStorage.setItem('endDate', e1);

    console.log(`Selected date range: ${formattedStartDate} - ${formattedEndDate}`);
  }
};

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const fetchData = async () => {
    localStorage.setItem("accessToken", prefix);
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      window.location.href = "/react/login";
      return;
    }

    const [startDate, endDate] = dates;
    // Format dates
    const formattedStartDate = startDate ? format(startDate, "MM/dd/yyyy") : "";
    const formattedEndDate = endDate ? format(endDate, "MM/dd/yyyy") : "";
    const reportrange = (formattedStartDate && formattedEndDate) ? `${formattedStartDate} - ${formattedEndDate}` : "";

    const s1 = startDate ? format(startDate, "yyyy-MM-dd") : "";
    const e1 = endDate ? format(endDate, "yyyy-MM-dd") : "";

    localStorage.setItem("startDate", s1);
    localStorage.setItem("endDate", e1);

    // Create a new FormData object
    const formData = new FormData();
    formData.append("reportrange", reportrange);
    formData.append("role", selectedRole);
    formData.append("prefix", prefix);

    // Construct your API endpoint URL
    const apiUrl = `${config.API_URL}/get_dashboard_count`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch statistics data");
      }

      const data = await response.json();
      // console.log(data);
      setStatisticsData(data);
    } catch (error) {
      console.error("Error fetching statistics data:", error);
    }
  };

  const handleSubmit = () => {
    fetchData(); // Fetch data when the "Submit" button is clicked
  };

  const fetchUserData = async () => {
    const formData = new FormData();
    formData.append("id", userId);
    formData.append("prefix", prefix);
    try {
      const response = await axios.post(
        `${config.API_URL}/get_users_details_id`,
        formData,
        {
          headers: {
            authorization: `Bearer ${prefix}`,
          },
        }
      );
      const result = response.data;
      if (result.success == true) {
        localStorage.setItem("site_name", result.data.site_name);
        localStorage.setItem("first_name", result.data.first_name);
        localStorage.setItem("last_name", result.data.last_name);
        localStorage.setItem("isSinglePicking", result.data.isSinglePicking);
        localStorage.setItem("shopifyConnectivity", result.data.shopifyConnectivity);
        fetchData();
      } else {
        window.location.href = `${config.LOG_OUT}`;
      }
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  useEffect(() => {
    if (prefix) {
      localStorage.setItem("accessToken", prefix);
      localStorage.setItem("userId", userId);
      fetchUserData();
    } else {
      window.location.href = `${config.LOG_OUT}`;
    }
  }, [prefix, userId]);

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>
              <span className="last-crumb">Dashboard</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">

            <div className="dash-calender-area">
            <div className="row">
                    
                     
                        <div className="col-12 col-lg-7 col-sm-7">
                          <div className="dashform">
                          <DateRangePicker
                                value={dates}
                                placeholder="DD/MM/YYYY - DD/MM/YYYY"
                                onChange={handleDateChange}
                                format="dd/MM/yyyy"
                                character=" - "
                                className="dashdate"
                              />
                            <button
                              type="button"
                              id="submit"
                              className="multisubmit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-lg-5 col-sm-5">
                          <div className="dashdate-area">
                            From:{" "}
                            {statisticsData ? statisticsData.from_date : "0"}{" "}
                            &nbsp; To:{" "}
                            {statisticsData ? statisticsData.to_date : "0"}
                          </div>
                        </div>
                      
                    </div>
                  </div>

              <div className="show-order-area dashboard">
                <div>

                

                <h2>
                  <span>
                    <i className="fa fa-th"></i> Statistics
                  </span>
                 
                </h2>

                

                </div>

                <div className="col-12 col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-xl-3 col-sm-6">
                      <div className="info-card sales-card leaf-green">
                        <div className="card-body">
                          <Link to="/dashboard/SalesOrder">
                            <p> Sales Order </p>
                            <b id="sales_order_grand_total">
                              £
                              {statisticsData
                                ? parseFloat(
                                    statisticsData.sales_grand_total
                                  ).toFixed(2)
                                : "0.00"}
                            </b>{" "}
                            <b id="sales_order_item_tax">
                              VAT £
                              {statisticsData
                                ? parseFloat(
                                    statisticsData.sales_item_tax
                                  ).toFixed(2)
                                : "0.00"}
                            </b>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                      <div className="info-card sales-card tomatored">
                        <div className="card-body">
                          <Link to="/dashboard/DeliveredInvoice">
                            <p> Delivered Invoice </p>
                            <b id="delivered_invoice_grand_total">
                              £
                              {statisticsData
                                ? parseFloat(
                                    statisticsData.delivered_invoice_grand_total
                                  ).toFixed(2)
                                : "0.00"}
                            </b>{" "}
                            <b id="delivered_invoice_item_tax">
                              VAT £
                              {statisticsData
                                ? parseFloat(
                                    statisticsData.delivered_invoice_item_tax
                                  ).toFixed(2)
                                : "0.00"}
                            </b>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/*<div className="col-xl-3 col-sm-6">
                          <div className="info-card sales-card babyellow">
                            <div className="card-body">
                              <a href="#">
                                <p> Sage Push Invoice </p>
                                <b id="sage_push_invoice_grand_total">£{statisticsData ? statisticsData.sage_push_invoice_grand_total : '0'}</b>{' '}
                                <b id="sage_push_invoice_item_tax">VAT £{statisticsData ? statisticsData.sage_push_invoice_item_tax : '0'}</b>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                          <div className="info-card sales-card beetpink">
                            <div className="card-body">
                              <a href="#">
                                <p> Pending Sage Push Invoice </p>
                                <b id="pending_sage_push_invoice_grand_total">
                                  £{statisticsData ? statisticsData.pending_sage_push_invoice_grand_total : '0'}
                                </b>{' '}
                                <b id="pending_sage_push_invoice_item_tax">
                                  VAT £{statisticsData ? statisticsData.pending_sage_push_invoice_item_tax : '0'}
                                </b>{' '}
                              </a>
                            </div>
                          </div>
                        </div>*/}
                  </div>
                </div>

                <h2>
                  <span>
                    <i className="fa fa-calendar"></i> DAILY MIS
                  </span>
                </h2>

                <div className="col-12 col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link
                        to="/dashboard/NewSales"
                        className="bred white quick-button"
                      >
                        <p className="yellow">
                          New <br /> Sales
                        </p>
                        <b>{statisticsData ? statisticsData.new_sales : 0}</b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link
                        to="/dashboard/OrderAccepted"
                        className="blightOrange white quick-button"
                      >
                        <p className="yellow">
                          Order <br /> Accepted
                        </p>
                        <b>{statisticsData ? statisticsData.sale_accept : 0}</b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link
                        to="/dashboard/picklistGenerated"
                        className="bgrey white quick-button"
                      >
                        <p className="yellow">
                          Picklist <br /> Generated
                        </p>
                        <b>{statisticsData ? statisticsData.picklistgenerated : 0}</b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link
                        to="/dashboard/PickingConfirm"
                        className="blightBlue white quick-button"
                      >
                        <p className="yellow">
                          Picking <br /> Confirmed
                        </p>
                        <b>
                          {statisticsData
                            ? `${statisticsData.picking_confirmed} (${statisticsData.picking_confirmed_inv})`
                            : 0}
                        </b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link to="/dashboard/ManifestGenerated" className="bpink white quick-button">
                        <p className="yellow">
                          Manifest <br /> Generated
                        </p>
                        <b>
                          {statisticsData
                            ?  `${statisticsData.manifestgenerated} (${statisticsData.manifest_order})`
                            : 0}
                        </b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link to="/dashboard/DeliveryConfirmed" className="bdarkGreen white quick-button">
                        <p className="yellow">
                          Delivery <br /> Confirmed
                        </p>
                        <b>
                          {statisticsData
                            ? statisticsData.delivery_confirmed
                            : 0}
                        </b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link
                        to="/dashboard/TopSales"
                        className="bred white quick-button"
                      >
                        <p className="yellow">
                          Top <br /> Sales
                        </p>
                        <b id="top_sales">
                          {statisticsData
                            ? `${
                                statisticsData.top_sales
                              } £${statisticsData.top_sales_amount.toFixed(2)}`
                            : 0.0}
                        </b>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2">
                      <Link
                        to="/dashboard/MaxValueOrder"
                        className="bred white quick-button"
                      >
                        <p className="yellow">
                          Max Value <br /> Order
                        </p>
                        <b id="top_customer_sales">
                          {statisticsData
                            ? `${
                                statisticsData.top_customer_sales
                              } £${statisticsData.top_customer_amount.toFixed(
                                2
                              )}`
                            : 0.0}
                        </b>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
