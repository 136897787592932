import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import * as XLSX from 'xlsx';

const MisReport = () => {
	const [reportrange, setReportrange] = useState('');
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(50);
	const [dates, setDates] = useState([null, null]);
	const [reportDate, setReportDate] = useState('');
	const [misReport, setMisReport] = useState([]);
	const salesOrderRef = useRef(null);
	const [selectedValue, setSelectedValue] = useState('');
	const [salesRapOptions, setSalesRapOptions] = useState([]);
	const [salesRap, setSalesRap] = useState('');
	const [routeOptions, setRouteOptions] = useState([]);
	const [salesRoute, setSalesRoute] = useState('');
	const [recordsTotal, setRecordsTotal] = useState('');
	const accessToken = localStorage.getItem('accessToken');

	const handleDateChange = (value) => {
		setDates(value);
		let startDate = value[0] ? format(value[0], 'MM/dd/yyyy') : '';
		let endDate = value[1] ? format(value[1], 'MM/dd/yyyy') : '';
		setReportrange(startDate ? startDate + ' - ' + endDate : '');
		setReportDate(startDate + ' To Date : ' + endDate);
	};






	const formatDateRange = (dates) => {
		if (dates[0] && dates[1]) {
			const startDate = dates[0].toDateString();
			const endDate = dates[1].toDateString();
			return `${startDate} - ${endDate}`;
		}
		return 'Not selected';
	};

	const fetchSalesRapOptions = async () => {

		const apiSalesRapUrl = `${config.API_URL}/getSalesRep`;
		try {
			const response = await fetch(apiSalesRapUrl, {
				method: 'POST',
				headers: {
					// 'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				}
			});

			const data = await response.json();
			const formattedOptions = data.data.map(item => ({
				key: `${item.first_name} ${item.last_name}`,
				value: item.id,
			}));
			setSalesRapOptions(formattedOptions);
		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};

	const fetchSalesRoute = async () => {

		const apiSalesRouteUrl = `${config.API_URL}/getRoute`;
		try {
			const response = await fetch(apiSalesRouteUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				}
			});

			const data = await response.json();
			const formattedOptions = data.data.map(item => ({
				key: `${item.route_number}`,
				value: item.id,
			}));
			setRouteOptions(formattedOptions);

		} catch (error) {
			console.error('api fail error fetching sales routes');
		}
	};

	const fetchReport = () => {
		const $table = $(salesOrderRef.current);
		const searchValue = $('#searchValue').val();

		// Destroy any existing DataTable instance to reinitialize with new data
		if ($.fn.DataTable.isDataTable($table)) {
			$table.DataTable().destroy();
		}

		// Initialize the DataTable with the new date range
		$table.DataTable({
			processing: true,
			serverSide: true,
			ajax: {
				url: `${config.API_URL}/mis_report`,
				type: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				data: function (d) {
					return $.extend({}, d, {
						draw: d.draw,
						start: d.start,
						length: d.length,
						searchValue: searchValue,
						reportrange: reportrange,
						sales_rep: salesRap,
						route_id: salesRoute,
						authorization: `Bearer ${accessToken}`,
					});
				},
				dataSrc: function (json) {
					if (json && json.data && Array.isArray(json.data)) {
						//setMisReport(json);
						setRecordsTotal(json.recordsTotal);
						setReportDate(json.startdate + ' To Date : ' + json.enddate);
					}

					return Array.isArray(json.data) ? json.data : [];
				},
			},
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + 1;
					}
				},
				{ data: 'date' },
				{
					data: null,
					render: function (data, type, row) {

						return '<a href="' + config.BASE_URL + '/reports/mis_report/print/' + row.id + '">' + row.reference_no + '</a>';
					}
				},
				{ data: 'order_amt' },
				{ data: 'accept_amt' },
				{ data: 'picking_amt' },
				{ data: 'deliver_amt' },
				{ data: 'invoice_amt' },
				{ data: 'total_tax' },
				{ data: 'sale_status' },

			],
			columnDefs: [
				{
					targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
					orderable: false
				}
			],
			pageLength: 10,
		});
	};

	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}

		fetchReport();
		fetchSalesRapOptions();
		fetchSalesRoute();
	}, []);

	// useEffect(() => {
	// 	fetchReport();
	// }, [reportrange, salesRap, salesRoute]);

	const searchFormData = () => {
		// let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
		// let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
		// setReportDate(startDate +' To Date : '+ endDate);
		// alert(startDate);
		// if((salesRap || salesRoute) && startDate === '')
		// {
		fetchReport();
		// }
	};

	const handleOptionChange = (event) => {
		const value = event.target.value;
		setSalesRap(value);
	};

	const handleChangeRoute = (event) => {
		const value = event.target.value;
		setSalesRoute(value);
	};

	const exportToExcel = async () => {
	const searchValue = $('#searchValue').val();  // Add 'async' here
  const formData = new FormData();
  formData.append('draw', '1');
  formData.append('start', '0');
  formData.append("length", recordsTotal);
  formData.append("searchValue", searchValue);
  formData.append("reportrange", reportrange);
  formData.append("sales_rep", salesRap);
  formData.append("route_id", salesRoute);
  formData.append("authorization", `Bearer ${accessToken}`);

  const apiActivePdtUrl = `${config.API_URL}/mis_report`;

  try {
    const response = await fetch(apiActivePdtUrl, {  // 'await' now works correctly
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Products');
    }

    const data = await response.json();
    const exportData = data.data.map(product => ({
      Date: product.date || '',
      "Order Number": product.reference_no || '',
      "Order": product.order_amt || '',
      "Accept": product.accept_amt || '',
      "Picking": product.picking_amt || '',
      "Delivered": product.deliver_amt || '',
      "Invoice": product.invoice_amt || '',
      "Status": product.sale_status || ''
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Mis Report');

    // Get current date and time for the filename (e.g., '2024-11-07-14-30-45')
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
    const filename = `mis-report-${formattedDate}-${formattedTime}.xlsx`;
    XLSX.writeFile(wb, filename);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
};

	return (
		<div>
			<Header />
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
							<Link to="/reports/mis_report">Reports</Link>
							<Link to="/reports/mis_report">mis report</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">

						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Date</b></label>
								<div class="col-sm-8">
									<DateRangePicker
										value={dates}
										placeholder="DD/MM/YYYY - DD/MM/YYYY"
										onChange={handleDateChange}
										format="dd/MM/yyyy"
										character=" - "
										style={{ width: '100%' }}
									/>
								</div>
							</div>
						</div>

						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Sales Rep</b></label>
								<div class="col-sm-8">
									<select value={salesRap} onChange={handleOptionChange} className="form-control selectinput" name="" id="" >
										<option value="">Select a Sales Rep</option>
										{salesRapOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.key}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Route</b></label>
								<div class="col-sm-8">
									<select value={salesRoute} onChange={handleChangeRoute} className="form-control selectinput" name="" id="" >
										<option value="">Select Route</option>
										{routeOptions.map(option => (
											<option key={option.value} value={option.value}>
												{option.key}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						<div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
							<button
								type="button"
								onClick={searchFormData}
								className="acceptbtn"
								style={{ float: 'left' }}
							>
								Submit
							</button>
						</div>





					</div>
					<div className="total-manifests-area dashboard">
						<p>
							<b>MIS Report From Date : {reportDate}</b>
							
								<button className="create-catalogue-btn" onClick={exportToExcel} style={{ textTransform: 'capitalize' }}>
									Export
								</button>
							
						</p>
						<div className="verical-scrl-wrapper common-table autoheight-table misOrderReport" id="style-3">
							<table className="table table-bordered resizable" id="misOrderReport" ref={salesOrderRef}>
								<thead>
									<tr>
										<th>Sr.no</th>
										<th>Date</th>
										<th>Order No</th>
										<th>Order</th>
										<th>Accept</th>
										<th>Picking</th>
										<th>Deliverd</th>
										<th>Invoice</th>
										<th>VAT</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
								</tbody>
								<tfoot>
								</tfoot>
							</table>
						</div>
					</div>

				</div>
			</div>

			<Footer />
		</div>
	);

}

export default MisReport;
