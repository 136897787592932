import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const About = ({ accessToken }) => 
{
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => 
  {
   
    const title = localStorage.getItem('site_name');

    setPageTitle(title); 
  }, [accessToken]); 

  return (
  
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
     
  );
};

export default About;
