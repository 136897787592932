  import React, { useState, useEffect } from "react";
  import Header from "../Header";
  import Footer from "../Footer";
  import Select from "react-select";
  import { API_URL } from "../Config";
  import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
  import $ from "jquery";
  import "datatables.net-bs5";
  import Swal from "sweetalert2";
  import config from "../common/Config";
  import {useParams, useNavigate, Link } from "react-router-dom";
  import axios from "axios";

  const Edit_Promos = () => {
    const [promoSelected, setPromoSelected] = useState(0);
    const [productInputValue, setProductInputValue] = useState("");
    const [productFilteredOptions, setProductFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);
    const [productOptions, setProductOptions] = useState([]);
    const [promoData, setPromoData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    //for combo promotion
    const [buyP, setBuyP] = useState([]);
    const [getP, setGetP] = useState([]);
    const navigateToPromotionList = useNavigate(); 
    const { id } = useParams();
    // for Product discount
    const [productList, setProductList] = useState([]);
    const accessToken = localStorage.getItem("accessToken");

    const [inputBox, seInputBox] = useState('amount');
    const [inputInvoiceType, setInputInvoiceType] = useState("amount");
    
    useEffect(() => {
      getPromotionData();
      fetchProductList();
      setBuyP([]);
      setGetP([]);
    }, [promoSelected]);

    const getPromotionData = async () => {
      const formData = new FormData();
      formData.set('promoId', id);
      const apiUrl = `${API_URL}/edit_promation`;
      try {
          const response = await axios.post(apiUrl, formData, {
              headers: {
                  authorization: `Bearer ${accessToken}`,
              },
          });

          var data = response.data.data;
          // console.log(response.data.success);
          if (response.data.success) {
            setPromoData(data.promo);
            if(data.promo.type === 'combo')
            {
              setBuyP(data.combo_items);
              setGetP(data.get_items);
              setPromoSelected(1);
            }
            else if(data.promo.type === 'buy_get')
              {
                setBuyP(data.buy_get);
                setGetP(data.get_items);
                setPromoSelected(2);
              }
            else if(data.promo.type === 'invoice_amount')
            {
              setPromoSelected(3);
                 seInputBox(data.promo.gettype)
            }
            else if(data.promo.type === 'product_discount')
            {
                setProductList(data.get_product);
                setPromoSelected(4);
                seInputBox(data.promo.gettype)
            }
          } 
      } catch (error) {
          console.error('Error deleting promotion:', error);
      }
  };

    const productHandleInputChange = (inputValue) => {
      setProductInputValue(inputValue);
      const newFilteredOptions = productOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setProductFilteredOptions(newFilteredOptions);
    };

    const productHandleChange = (selectedOption, type) => {
      if (selectedOption) {
        setProductValue(selectedOption);
        fetchAddProductInfo(selectedOption.value, type);
      } else {
        setProductValue(null);
      }
    };
    // product_disc
    const fetchAddProductInfo = async (selectedOption, type) => {
      if (promoSelected === 1) {
        if (type === "get_combo" && getP.length === 1) {
          Swal.fire({
            title: "Failed!",
            text: "Only one product can be added",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }
        if (type === "buy_combo") {
        const existingProduct = buyP.find((item) => item.id === selectedOption);
        if (existingProduct) {
          Swal.fire({
            title: "Failed!",
            text: "Product is already present",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }
       }
      }

      if (promoSelected === 2) {
        if (type === "get_combo_1" && getP.length === 1) {
          Swal.fire({
            title: "Failed!",
            text: "Only one product can be added",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }

        if (type === "buy_combo_1" && buyP.length === 1) {
          Swal.fire({
            title: "Failed!",
            text: "Only one product can be added",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }
      }

      const formData = new FormData();
      formData.append("product_id", selectedOption);

      const apiUrl = `${API_URL}/purchases/get_order_datails`;
      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });

        const productData = response.data[0];
        
        const newRow = {
          id: productData.id,
          code: productData.code,
          name: productData.name,
          size: productData.size,
          split: productData.split,
          code: productData.code
        };
        if (promoSelected === 1) 
        {
          if (type === "buy_combo") {
            setBuyP((prevRows) => [...prevRows, newRow]);
          } else if (type === "get_combo") {
            setGetP((prevRows) => [...prevRows, newRow]);
          }
        }
        else if(promoSelected === 2)
          {
            if (type === "buy_combo_1") {
              setBuyP((prevRows) => [...prevRows, newRow]);
            } else if (type === "get_combo_1") {
              setGetP((prevRows) => [...prevRows, newRow]);
            }
          }
        else if(promoSelected === 4)
        {
          if (type === "product_disc") {
            setProductList((prevRows) => [...prevRows, newRow]);
          }
        }

      } catch (error) {
        console.error("API fetch error:", error);
      }
    };

    const fetchProductList = async () => {
      const apiSalesRouteUrl = `${API_URL}/purchases/getProducts`;
      try {
        const response = await fetch(apiSalesRouteUrl, {
          method: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();
        const formattedOptions = data.products.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setProductOptions(formattedOptions);
        setProductFilteredOptions(formattedOptions);
      } catch (error) {
        console.error("api fail error fetching products");
      }
    };

    const updatePromotion = async () => {
      let form = "";
      let promotion_type = "";
      if (promoSelected == 1) {
        promotion_type = "combo";
        form = document.getElementById("combo_promotion_form");
      } else if (promoSelected == 2) {
        promotion_type = "buy_get";
        form = document.getElementById("buynget_promotion_form");
      } else if (promoSelected == 3) {
        promotion_type = "invoice_amount";
        form = document.getElementById("inv_amt_promotion_form");
      } else if (promoSelected == 4) {
        promotion_type = "product_discount";
        form = document.getElementById("product_disc_promotion_form");
      }

      let name = $("#name").val();
      let startDate = $("#startDate").val();
      let endDate = $("#endDate").val();
      let description = $("#description").val();
      const formData = new FormData(form);
      formData.set("name", name);
      formData.set("start_date", startDate);
      formData.set("end_date", endDate);
      formData.set("description", description);
      formData.set("type_promos", promotion_type);
      formData.set("id", id);
      
      try {
        const response = await axios.post(`${API_URL}/update_promation`, formData, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });

          const result = response.data;
          console.log(result);
          // alert(result.success);
          if (result.success == true)
            {
            Swal.fire({
              title: "Success!",
              text: "Promotion updated successfully.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() =>
          {
             navigateToPromotionList('/Promotions/Promos');
          });

          }
          else{
            Swal.fire({
              title: "Failed!",
              text: "Failed to update promotion. Try Again",
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          }
      } catch (error) {
        console.error("Error creating promotion :", error);
      }
    };

    const handleRemove = (id, type) => {
      if(type === 'buy')
      {
        setBuyP(buyP.filter((product) => product.id !== id));
      }
      else if(type === 'get')
      {
        setGetP(getP.filter((product) => product.id !== id));
      }
      else if(type === 'productDisc')
      {
        setProductList(productList.filter((product) => product.id !== id));
      }
    };
  const handleCheckboxChange = () => {
      setIsChecked((prev) => !prev);
    };
    return (
      <div>
        <Header />
        <nav className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <Link to="/Dashboard">
                  <i className="fa fa-home"></i>
                </Link>
                <Link to="/Promotions/Promos">Promotions</Link>
                <Link to="#">Edit Promotion</Link>
              </div>
            </div>
          </div>
        </nav>
        <div className="main-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <div style={{ position: "relative" }} >
                  <div className="">
                    <div className="add-promo-popup">
                      <form id="addcustomerform">
                        <div  className="row mb-3 padd-l-13px">
                          <div  className="col-sm-9">
                            <label htmlFor="inputEmail5"  className="col-form-label">
                              Type
                            </label>
                            <div  className="col-sm-10">
                              <div  className="form-check">
                                {/* <input type="text" value={promoSelected} /> */}
                                <input
                                   className="form-check-input"
                                  type="radio"
                                  name="gridRadios"
                                  id="gridRadios1"
                                  value="option1"
                                  checked={promoSelected === 1}
                                />
                                <label  className="form-check-label" htmlFor="gridRadios1">
                                  {" "}
                                  Combo{" "}
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                   className="form-check-input"
                                  type="radio"
                                  name="gridRadios"
                                  id="gridRadios2"
                                  value="option2"
                                  checked={promoSelected === 2}
                                />
                                <label  className="form-check-label" htmlFor="gridRadios2">
                                  {" "}
                                  Buy Get
                                </label>
                              </div>

                              <div  className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gridRadios"
                                  id="gridRadios3"
                                  value="option3"
                                  checked={promoSelected === 3}
                                />
                                <label  className="form-check-label" htmlFor="gridRadios3">
                                  {" "}
                                  Invoice Amount
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                   className="form-check-input"
                                  type="radio"
                                  name="gridRadios"
                                  id="gridRadios4"
                                  value="option4"
                                  checked={promoSelected === 4}
                                />
                                <label  className="form-check-label" htmlFor="gridRadios4">
                                  Product Discount
                                </label>
                              </div>

                              
                            </div>
                          </div>
                        </div>
                       
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label" htmlFor="name">
                                Name <span className="red">*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="form-control tip"
                                autoComplete="off"
                                defaultValue={promoData.name}
                                required readOnly
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                className="col-form-label"
                                htmlFor="start_date"
                              >
                                Start Date <span className="red">*</span>
                              </label>
                              <input
                                type="date"
                                name="start_date"
                                id="startDate"
                                className="form-control tip date"
                                defaultValue={promoData.start_date}
                                required readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label
                                className="col-form-label"
                                htmlFor="end_date"
                              >
                                End Date <span className="red">*</span>
                              </label>
                              <input
                                type="date"
                                name="end_date"
                                id="endDate"
                                className="form-control tip date"
                                defaultValue={promoData.end_date}
                                required readOnly
                              />
                            </div>
                          </div>
                        </div>
                        {promoSelected === 1 && (
                          <>
                            <form id="combo_promotion_form">
                              <div className="row mb-3">
                                <div className="col-md-6">
                                
                                  <div className="form-group">
                              <label className="col-form-label" htmlFor="name">
                              Add Product <span className="red">*</span>
                              </label>
                              <div style={{ float: 'left', width: '100%'}}>
                              <Select
                                      isClearable
                                      onInputChange={productHandleInputChange}
                                      onChange={(e) =>
                                        productHandleChange(e, "buy_combo")
                                      }
                                      options={productFilteredOptions}
                                      placeholder="Select Product"
                                    />

                                </div>
                                </div>
                                </div>
                                <div className="col-md-6">
                                  <label className="col-form-label">
                                    Add Product <span className="red">*</span>
                                  </label>
                                  <div style={{ float: 'left', width: '100%'}}>
                                  <Select
                                    isClearable
                                    data-type="get_combo"
                                    onInputChange={productHandleInputChange}
                                    onChange={(e) =>
                                      productHandleChange(e, "get_combo")
                                    }
                                    options={productFilteredOptions}
                                    placeholder="Select Product"
                                  />
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-6">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-12 col-sm-12">
                                      <label
                                        htmlFor="name1"
                                        className="col-form-label"
                                      >
                                        Buy Products{" "}
                                      </label>
                                      <div className="buyprct table">
                                        <table className="table table-bordered dataTable resizable">
                                          <thead>
                                            <tr>
                                              <th>Product (Code - Name) </th>
                                              <th style={{width: '100px'}}>Type</th>
                                              <th style={{width: '100px'}}>Qty </th>
                                              <th style={{width: '50px'}}>
                                                <span className="fa fa-trash"></span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {buyP.length === 0 ? (
                                              <tr style={{ textAlign: 'center' }}>
                                                <td colSpan={4}>No product added</td>
                                              </tr>
                                            ) : (
                                              buyP.map((row) => (
                                                <tr key={row.id}>
                                                  <td>
                                                    {row.code} - {row.name} - {row.size}
                                                    <input type="hidden" name="product2buy_id[]" value={row.id} />
                                                    <input type="hidden" name="product2buy[]" value={row.name} />
                                                    <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <select className="form-select" name="product_buy_type[]">
                                                      {row.split === '0' && <option value="0">Box</option>}
                                                      {row.split === '1' && <option value="1">Piece</option>}
                                                      {row.split === '2' && (
                                                        <>
                                                        <option value="0" selected={row.product_buy_type == "0"}>Box</option>
                                                        <option value="1" selected={row.product_buy_type == "1"}>Piece</option>
                                                        </>
                                                      )}
                                                    </select>
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <input
                                                      type="text"
                                                      name="quantity[]"
                                                      disabled
                                                      value={1}
                                                      style={{ textAlign: 'center' }}
                                                    />
                                                  </td>
                                                  <td>
                                                    {/* <button
                                                      type="button"
                                                      className="btn btn-danger cancel-btn"
                                                      onClick={() => handleRemove(row.id, 'buy')}
                                                      style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                    >
                                                      <span className="fa fa-trash-o"></span>
                                                    </button> */}

                                  <a class="icon viewbtn" onClick={() => handleRemove(row.id, 'buy')}>  <i class="fa fa-trash-o"></i></a>

                                                  </td>
                                                </tr>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-12 col-sm-12">
                                      <label
                                        htmlFor="name1"
                                        className="col-form-label"
                                      >
                                        Get Products{" "}
                                      </label>
                                      <div className="buyprct table">
                                        <table className="table table-bordered dataTable resizable">
                                          <thead>
                                            <tr>
                                              <th>Product (Code - Name) </th>
                                              <th style={{width: '100px'}}>Type</th>
                                              <th style={{width: '100px'}}>Qty </th>
                                              <th style={{width: '50px'}}>
                                                <span className="fa fa-trash"></span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {getP.length === 0 ? (
                                              <tr style={{ textAlign: 'center' }}>
                                                <td colSpan={4}>No product added</td>
                                              </tr>
                                            ) : (
                                              getP.map((row) => (
                                                <tr key={row.id}>
                                                  <td>
                                                    {row.code} - {row.name} - {row.size}
                                                    <input type="hidden" name="product2get_id[]" value={row.id} />
                                                    <input type="hidden" name="product2get[]" value={row.name} />
                                                    <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <select className="form-select" name="product_get_type[]">
                                                      {row.split === '0' && <option value="0">Box</option>}
                                                      {row.split === '1' && <option value="1">Piece</option>}
                                                      {row.split === '2' && (
                                                        <>
                                                          <option value="0" selected={row.product_get_type == "0"}>Box</option>
                                                          <option value="1" selected={row.product_get_type == "1"}>Piece</option>
                                                        </>
                                                      )}
                                                    </select>
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <input
                                                      type="text"
                                                      name="quantity[]"
                                                      disabled
                                                      value={1}
                                                      style={{ textAlign: 'center' }}
                                                    />
                                                  </td>
                                                  <td>
                                                    {/* <button
                                                      type="button"
                                                      className="btn btn-danger cancel-btn"
                                                      onClick={() => handleRemove(row.id, 'get')}
                                                      style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                       >
                                                      <span className="fa fa-trash-o"></span>
                                                    </button> */}

                                            <a class="icon viewbtn" onClick={() => handleRemove(row.id, 'get')}>  <i class="fa fa-trash-o"></i></a>
                                                  </td>
                                                </tr>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Total Buy Qty <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                       className="form-control"
                                      name="total_buy_qty"
                                      autoComplete="off"
                                      required="required"
                                      defaultValue={promoData.quantity}
                                    />
                                  </div>
                                </div>

                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                      Total Get Qty <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                       className="form-control"
                                      name="total_get_qty"
                                      autoComplete="off"
                                      autoCapitalize="on"
                                      required="required"
                                      defaultValue={promoData.getquantity}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Sub type
                                    </label>
                                    <select
                                      id="inputState"
                                      name="subtype"
                                       className="form-select"
                                    >
                                      <option value="any">Any</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </>
                        )}

                        {promoSelected === 2 && (
                          <>
                            <form id="buynget_promotion_form">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label  className="col-form-label"  htmlFor="add_item1"  >
                                      Add Product <span className="red">*</span>
                                    </label>
                                    <div style={{ float: 'left', width: '100%'}}>
                                    <Select
                                    isClearable
                                    data-type="get_combo"
                                    onInputChange={productHandleInputChange}
                                    onChange={(e) =>
                                      productHandleChange(e, "buy_combo_1")
                                    }
                                    options={productFilteredOptions}
                                    placeholder="Select Product"
                                  /></div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="col-form-label"
                                      htmlFor="add_item2"
                                    >
                                      Add Product <span className="red">*</span>
                                    </label>
                                    <div style={{ float: 'left', width: '100%'}}>
                                    <Select
                                    isClearable
                                    data-type="get_combo"
                                    onInputChange={productHandleInputChange}
                                    onChange={(e) =>
                                      productHandleChange(e, "get_combo_1")
                                    }
                                    options={productFilteredOptions}
                                    placeholder="Select Product"
                                  />

                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-12 col-sm-12">
                                      <label
                                        htmlFor="name1"
                                        className="col-form-label"
                                      >
                                        Buy Products{" "}
                                      </label>
                                      <div className="buyprct table">
                                        <table className="table table-bordered dataTable resizable">
                                          <thead>
                                            <tr>
                                              <th>Product (Code - Name) </th>
                                              <th style={{width: '100px'}}>Type</th>
                                              <th style={{width: '100px'}}>Qty </th>
                                              <th style={{width: '50px'}}>
                                                <span className="fa fa-trash"></span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {buyP.length === 0 ? (
                                              <tr style={{ textAlign: 'center' }}>
                                                <td colSpan={4}>No product added</td>
                                              </tr>
                                            ) : (
                                              buyP.map((row) => (
                                                <tr key={row.id}>
                                                  <td>
                                                    {row.code} - {row.name} - {row.size}
                                                    <input type="hidden" name="product2buy_id[]" value={row.id} />
                                                    <input type="hidden" name="product2buy[]" value={row.name} />
                                                    <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <select className="form-select" name="product_buy_type[]">
                                                      {row.split === '0' && <option value="0">Box</option>}
                                                      {row.split === '1' && <option value="1">Piece</option>}
                                                      {row.split === '2' && (
                                                        <>
                                                        <option value="0" selected={row.product_buy_type == "0"}>Box</option>
                                                        <option value="1" selected={row.product_buy_type == "1"}>Piece</option>
                                                      </>
                                                      )}
                                                    </select>
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <input
                                                      type="text"
                                                      name="quantity[]"
                                                      defaultValue={row.qty}
                                                      style={{ textAlign: 'center' }}
                                                    />
                                                  </td>
                                                  <td>
                                                    {/* <button
                                                      type="button"
                                                      className="btn btn-danger cancel-btn"
                                                      onClick={() => handleRemove(row.id, 'buy')}
                                                      style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                    >
                                                      <span className="fa fa-trash-o"></span>
                                                    </button> */}

                                                    <a class="icon viewbtn" onClick={() => handleRemove(row.id, 'buy')}>  <i class="fa fa-trash-o"></i></a>

                                                  </td>
                                                </tr>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-12 col-sm-12">
                                      <label
                                        htmlFor="name1"
                                        className="col-form-label"
                                      >
                                        Get Products{" "}
                                      </label>
                                      <div className="buyprct table">
                                        <table className="table table-bordered dataTable resizable">
                                          <thead>
                                            <tr>
                                              <th>Product (Code - Name) </th>
                                              <th style={{width: '100px'}}>Type</th>
                                              <th style={{width: '100px'}}>Qty </th>
                                              <th style={{width: '50px'}}>
                                                <span className="fa fa-trash"></span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {getP.length === 0 ? (
                                              <tr style={{ textAlign: 'center' }}>
                                                <td colSpan={4}>No product added</td>
                                              </tr>
                                            ) : (
                                              getP.map((row) => (
                                                <tr key={row.id}>
                                                  <td>
                                                    {row.code} - {row.name} - {row.size}
                                                    <input type="hidden" name="product2get_id[]" value={row.id} />
                                                    <input type="hidden" name="product2get[]" value={row.name} />
                                                    <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <select className="form-select" name="product_get_type[]">
                                                      {row.split === '0' && <option value="0">Box</option>}
                                                      {row.split === '1' && <option value="1">Piece</option>}
                                                      {row.split === '2' && (
                                                        <>
                                                        <option value="0" selected={row.product_get_type == "0"}>Box</option>
                                                        <option value="1" selected={row.product_get_type == "1"}>Piece</option>
                                                        </>
                                                      )}
                                                    </select>
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <input
                                                      type="text"
                                                      name="getquantity[]"
                                                      defaultValue={row.qty}
                                                      style={{ textAlign: 'center' }}
                                                    />
                                                  </td>
                                                  <td>
                                                    {/* <button
                                                      type="button"
                                                      className="btn btn-danger cancel-btn"
                                                      onClick={() => handleRemove(row.id, 'get')}
                                                      style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                    >
                                                      <span className="fa fa-trash-o"></span>
                                                    </button> */}

                                                    <a class="icon viewbtn" onClick={() => handleRemove(row.id, 'get')}>  <i class="fa fa-trash-o"></i></a>

                                                  </td>
                                                </tr>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </>
                        )}

                        {promoSelected === 3 && (
                          <>
                            <form id="inv_amt_promotion_form">
                              <div  className="row mb-3">
                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                      Price <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="amount"
                                       className="form-control"
                                      required="required"
                                      defaultValue={promoData.amount}
                                    />
                                  </div>
                                </div>

                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                      Type <span className="red">*</span>
                                    </label>
                                    <select
                                      id="inputState"
                                      name="gettype"
                                       className="form-select"
                                       defaultValue={promoData.gettype}
                                    >
                                      <option value="amount">Amount</option>
                                      <option value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                 { inputBox === 'percentage' && ( 
                                <>
                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                    Percentage <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="percentage"
                                      className="form-control"
                                      required="required"
                                      defaultValue={promoData.percentage}
                                    />
                                  </div>
                                </div>
                                </>
                                )
                              }

                            { inputBox === 'amount' && ( 
                                <>
                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                      Amount <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="getamount"
                                      className="form-control"
                                      required="required"
                                      defaultValue={promoData.getamount}
                                    />
                                  </div>
                                </div>
                                </>
                                )
                              }

                              </div>
                            </form>
                          </>
                        )}

                        {promoSelected === 4 && (
                          <>
                            <form id="product_disc_promotion_form">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Add Product <span className="red">*</span>
                                    </label>
                                    <div style={{ float: 'left', width: '100%'}}>
                                    <Select
                                      isClearable
                                      onInputChange={productHandleInputChange}
                                      onChange={(e) =>
                                        productHandleChange(e, "product_disc")
                                      }
                                      options={productFilteredOptions}
                                      placeholder="Select Product"
                                    />
                                  </div>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Type <span className="red">*</span>
                                    </label>
                                    <select id="inputState" name="gettype1" onChange={(e)=>seInputBox(e.target.value)}  className="form-select" defaultValue={promoData.gettype}>
                                      <option value="amount">Amount</option>
                                      <option value="percentage">Percentage</option>
                                    </select>
                                  </div>
                                </div>

                              { inputBox === 'percentage' && ( 
                                <>
                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                    Percentage <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="percentage"
                                      className="form-control"
                                      required="required"
                                      defaultValue={promoData.percentage}
                                    />
                                  </div>
                                </div>
                                </>
                                )
                              }

                            { inputBox === 'amount' && ( 
                                <>
                                <div  className="col-md-3">
                                  <div  className="form-group">
                                    <label className="col-form-label">
                                      Amount <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="getamount"
                                      className="form-control"
                                      required="required"
                                      defaultValue={promoData.getamount}
                                    />
                                  </div>
                                </div>
                                </>
                                )
                              }

                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-12 col-sm-12">
                                      <label
                                        htmlFor="name1"
                                        className="col-form-label"
                                      >
                                        Products
                                      </label>
                                      <div className="buyprct table">
                                        <table className="table table-bordered dataTable resizable">
                                          <thead>
                                            <tr>
                                              <th>Product (Code - Name) </th>
                                              <th style={{width: '100px'}}>Type</th>
                                              <th style={{width: '50px'}}>
                                                <span className="fa fa-trash"></span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {productList.length === 0 ? (
                                              <tr style={{ textAlign: 'center' }}>
                                                <td colSpan={4}>No product added</td>
                                              </tr>
                                            ) : (
                                              productList.map((row) => (
                                                <tr key={row.id}>
                                                  <td>
                                                    {row.code} - {row.name} - {row.size}
                                                    <input type="hidden" name="product2buy_id[]" value={row.id} />
                                                    <input type="hidden" name="product2buy[]" value={row.name} />
                                                    <input type="hidden" name="combo_item_code[]" value={row.code} />
                                                  </td>
                                                  <td style={{width : '85px'}}>
                                                    <select className="form-select" name="product_buy_type[]">
                                                      {row.split === '0' && <option value="0">Box</option>}
                                                      {row.split === '1' && <option value="1">Piece</option>}
                                                      {row.split === '2' && (
                                                       <>
                                                       <option value="0" selected={row.type === "0"}>Box</option>
                                                       <option value="1" selected={row.type === "1"}>Piece</option>
                                                       </>
                                                      )}
                                                    </select>
                                                  </td>
                                                  <td style={{width : '35px'}}>
                                                    {/* <button
                                                      type="button"
                                                      className="btn btn-danger cancel-btn"
                                                      onClick={() => handleRemove(row.id, 'productDisc')}
                                                      style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                    >
                                                      <span className="fa fa-trash-o"></span>
                                                    </button> */}

                                    <a class="icon viewbtn" onClick={() => handleRemove(row.id, 'productDisc')}>  <i class="fa fa-trash-o"></i></a>

                                                  </td>
                                                </tr>
                                              ))
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </>
                        )}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="description" className="col-form-label">Description</label>
                              <textarea
                                name="description"
                                className="form-control skip"
                                id="description"
                                style={{ height: "100px" }}
                                defaultValue={promoData.description} readOnly
                              />
                            </div>
                          </div>
                       
                         <div className="col-12 col-lg-6 col-sm-6"> 

                         <label htmlFor="desc" className="col-form-label">Applicable for</label>
                          <div style={{ background: '#f5f5f5', float: 'left', width: '100%', border: '1px solid #eee', padding: '15px' }}>
                            <div className="row padd-0">
                              <div className="col-6">
                                <div className="col-12 col-lg-12 col-sm-12">
                                  <div>
                                    <label className="checkbox">
                                    <input type="checkbox" name="online_promation"  checked={isChecked}
                                          onChange={handleCheckboxChange}  readOnly     
                                        value="1" id="online_promation"/>
                                      <span className="default"></span>
                                    </label>
                                    <label className="form-check-label" htmlFor="online_promation">Online Customer Order</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>
                        <div className="col-12 col-lg-12 col-sm-12">
                          <hr />
                        </div>

                        <div className="row mb-3 padd-l-13px">
                          <div className="col-12 col-lg-12 col-sm-12">
                          {/* <a href="#" onClick={updatePromotion} className="float-right-btn common-popup-btn" >
                              Submit
                            </a>*/}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };

  export default Edit_Promos;
