import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../common/Config";
import Swal from "sweetalert2";
import { ca } from "date-fns/locale";

const ShopifyProduct = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const accessToken = localStorage.getItem("accessToken");
  const activePdtRef = useRef(null);
  const [totalActiveProduct, setTotalActiveProduct] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setCategoryValue(value);
  };

  const fetchCategoriesOptions = async () => {
    const apiCategoriesUrl = `${config.API_URL}/stock_take/getCategories`;
    try {
      const response = await fetch(apiCategoriesUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.data.category.map((item) => ({
        key: `${item.name}`,
        value: item.id,
      }));
      setCategoryOptions(formattedOptions);
    } catch (error) {
      console.error("api fail Error:", error);
    }
  };

  const getAcivepdtList = () => {
    const $table = $(activePdtRef.current);

    // Destroy any existing DataTable instance to reinitialize with new data
		if ($.fn.DataTable.isDataTable($table)) {
			$table.DataTable().destroy();
		}

      $table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: `${config.API_URL}/products/getactive_product`,
          type: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          data: function (d) {
            return $.extend({}, d, {
              draw: d.draw,
              start: d.start,
              length: 50,
              categoryValue: categoryValue, 
            });
          },
          dataSrc: function (json) {
            if (json && json.data && Array.isArray(json.data)) {
              setTotalActiveProduct(json.recordsFiltered);
            }
            return Array.isArray(json.data) ? json.data : [];
          },
        },
        language: {
          paginate: {
            first: "First",
            last: "Last",
            next: "Next",
            previous: "Previous",
          },
          search: "Search:",
        },
        columns: [
          {
            data: null,
            width: "1%",
            className: "text-center",
            render: function (data, type, row) {
              return `<div class="radio-tbl"><label class="checkbox"><input type="checkbox" class="checkbox multi-select input-xs" name="val[]" value="${row.id}"/><span class="default"></span></label></div>`;
            },
          },
          { data: "code", className: 'text-left' },
          {
            data: null,
            render: function (data, type, row) {
              return `<span title="${row.name}">${row.name}</span>`;
            },
          },
          { data: "categories", width: "5%" },
          {
            data: null,
            render: function (data, type, row) {
              const status = row.shopifyID === null ? 'Not Available' : 'Available';
              const color = row.shopifyID === null ? 'red' : 'green';
              return `<span style="background-color: ${color}; color: white; padding: 2px 5px; border-radius: 3px;">${status}</span>`;
            },
          },
        ],
        columnDefs: [
          {
            orderable: false,
            targets: "_all",
          }
        ],
        pageLength: 50,
      });
  };

  useEffect(() => {
    getAcivepdtList();
    fetchCategoriesOptions();
  }, []);

  useEffect(() => {
    if(categoryValue !== "") {
      getAcivepdtList();
      return;
    }
  }, [categoryValue]);

  const handleProductsSubmit = async () => {
    Swal.fire({
      title: 'Processing...',
      text: 'Please wait while we process all products.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const formData = new FormData();
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${config.API_URL}/shopify/submit-products-to-shopify`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit selection");
      }

      const data = await response.json();

      Swal.close();

      if (data.success === true) {
        Swal.fire({
          title: "Success!",
          text: "Products Created successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          getAcivepdtList();
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        title: "Error!",
        text: "Error submitting selection.",
        icon: "error",
      });
      console.error("Error submitting selection:", error);
    }
  };

  // Handle button click to submit selected products API
  const handleSelectionSubmit = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Processing...',
      text: 'Please wait while we process all products.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(
        `${config.API_URL}/shopify/submit-products-to-shopify`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit selection");
      }

      const data = await response.json();
      
      console.log(data);
      Swal.close();

      if (data.success === true) {
        Swal.fire({
          title: "Success!",
          text: "Products processed successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          getAcivepdtList();
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        title: "Error!",
        text: "Error submitting selection.",
        icon: "error",
      });
      console.error("Error submitting selection:", error);
    }
  };

  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i class="fa fa-home"></i>
              </a>{" "}
              &gt;
              <a href="Dashboard">Master</a> &gt;
              <span class="last-crumb">Product List</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: "relative" }}>
                <div>
                  <form id="picking_create" onSubmit={handleSelectionSubmit}>
                    <div className="dashform">
                      <div className="row">
                        <div
                          className="col-md-3 col-lg-4 col-sm-3"
                          style={{ float: "left", padding: "0px 0px 0px 0px" }}
                        >
                          <select
                            id="categoryId"
                            value={categoryValue}
                            onChange={handleCategoryChange}
                            className="form-select"
                          >
                            <option value="">Select Categories</option>
                            {categoryOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.key}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-3">
                        <button type="submit" className="multisubmit" style={{padding: '12px'}}>
                          Process Product
                        </button>
                        </div>

                        <div className="col-md-3 col-lg-5 col-sm-3" style={{ float: "right" }}>
                        <a
                      onClick={handleProductsSubmit}
                      className="btn btn-primary" style={{float: 'right'}}
                    >
                      Insert All Products
                    </a>
                        </div>
                      </div>
                    </div>

                    

                    <div className="show-order-area dashboard shopify-table">
                      <table
                        className="table table-bordered dataTable resizable "
                        id="pdtCategoryTable"
                        ref={activePdtRef}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "50px" }}>
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  const checkboxes = document.querySelectorAll(
                                    '#pdtCategoryTable tbody input[type="checkbox"]'
                                  );
                                  checkboxes.forEach(
                                    (checkbox) => (checkbox.checked = e.target.checked)
                                  );
                                }}
                              />
                            </th>
                            <th style={{ textAlign: "center" }}> Code</th>
                            <th> Name</th>
                            <th> Category </th>
                            <th> Shopify Status </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShopifyProduct;
