import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from '../common/Config';
import AccountService from '../../services/AccountServices';
import * as XLSX from 'xlsx';

const DsrReport = () => {
    const accessToken = localStorage.getItem('accessToken');
    const user_id = localStorage.getItem('userId');
    const [dates, setDates] = useState([null, null]);
    const [reportrange, setReportrange] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [salesRapOptions, setSalesRapOptions] = useState([]);
    const [salesRap, setSalesRap] = useState('');
    const [searchValue, setSearchValue] = useState([]);
    const locationReportRef = useRef(null);

    const handleDateChange = (value) => {
        setDates(value);
    };

    const searchFormData = () => {
        let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
        let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
        setReportrange(startDate ? startDate + ' - ' + endDate : '');
        getDsrReportList();
    };

    const formatDateRange = (dates) => {
        if (dates[0] && dates[1]) {
            const startDate = dates[0].toDateString();
            const endDate = dates[1].toDateString();
            return `${startDate} - ${endDate}`;
        }
        return 'Not selected';
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSalesRap(value);
    };

    const fetchSalesRapOptions = async () => {
        const apiSalesRapUrl = `${config.API_URL}/getSalesRep`;
        try {
            const response = await fetch(apiSalesRapUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });

            const data = await response.json();
            const formattedOptions = data.data.map(item => ({
                key: `${item.first_name} ${item.last_name}`,
                value: item.id,
            }));
            setSalesRapOptions(formattedOptions);
        } catch (error) {
            console.error('api fail Error fetching Not Order Report:', error);
        }
    };

    const getDsrReportList = () => {
        const $table = $(locationReportRef.current);
        const searchValue = $('#searchValue').val();
        if ($.fn.DataTable.isDataTable($table)) {
            $table.DataTable().destroy();
        }
        let i = 0;
        $table.DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                url: `${config.API_URL}/dsr_report_data`,
                type: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                data: function (d) {
                    return $.extend({}, d, {
                        draw: d.draw,
                        start: d.start,
                        length: d.length,
                        searchValue: searchValue,
                        reportrange: reportrange ? reportrange : '',
                        sales_rep_id: salesRap ? salesRap : '',
                    });
                },
                dataSrc: function (json) {
                    if (json && json.data && Array.isArray(json.data)) {
                        //setTotalActiveProduct(json.recordsFiltered); 
                    }
                    return Array.isArray(json.data) ? json.data : [];
                }
            },
            language: {
                paginate: {
                    first: 'First',
                    last: 'Last',
                    next: 'Next',
                    previous: 'Previous',
                },
                search: 'Search:',
            },
            columns: [
                { data: 'visit_date' },
                { data: 'time_of_visit' },
                {
                    data: null,  // Use null to access the whole row data
                    render: function (data, type, row) {
                        return `${row.company} ( ${row.accound_no} )`;  // Concatenating columns
                    }
                },
                { data: 'orderOrVisit' },
                { data: 'order_value' },
                { data: 'amount_collected' },
                { data: 'payment_mode' },
                { data: 'reason_or_description' },
                { data: 'salesRep_name' },
            ],
            columnDefs: [
                {
                    orderable: false,
                    targets: '_all'
                },
            ],
            pageLength: 50,
        });
    };

	const exportToExcel = () => {
		const $table = $(locationReportRef.current);
		const tableData = $table.DataTable().data().toArray();
	
		// Define headers
		const headers = [
			"Date", "Time", "Customer Name(A/C)", "Ordered / Visit", "Order Value", "Amt Collected", "Pay Mode", "Reason", "Sales Rep"
		];
	
		// Map table data to match headers
		const formattedData = tableData.map(row => ({
			Date: row.visit_date,
			Time: row.time_of_visit,
			"Customer Name(A/C)": `${row.company} (${row.accound_no})`,
			"Ordered / Visit": row.orderOrVisit,
			"Order Value": row.order_value,
			"Amt Collected": row.amount_collected,
			"Pay Mode": row.payment_mode,
			Reason: row.reason_or_description,
			"Sales Rep": row.salesRep_name
		}));
	
		// Create worksheet and workbook
		const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers });
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "DSR Report");
	
		// Write file
		XLSX.writeFile(workbook, "DSR_Report.xlsx");
	};

    useEffect(() => {
        if (accessToken === null) {
            window.location.href = `${config.BASE_URL}/login`;
            return;
        }
        fetchSalesRapOptions();
        getDsrReportList();
        return () => {
            if (locationReportRef.current && $.fn.DataTable.isDataTable(locationReportRef.current)) {
                $(locationReportRef.current).DataTable().destroy();
            }
        };
    }, [searchValue, reportrange]);

    return (
        <>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <Link to="/Dashboard"><i className="fa fa-home"></i></Link>
                            <Link to="/reports/lat_long_report">Report</Link>
                            <Link to="/reports/lat_long_report">Daily Sales Report</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="total-manifests-area dashboard" style={{ position: 'relative' }} >
                        <div className="row" style={{ marginBottom: '0px' }} >
                            <div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
                                <div class="row mb-3 padd-0">
                                    <label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>Select Date </b></label>
                                    <div class="col-sm-9">
                                        <DateRangePicker
                                            value={dates}
                                            placeholder="DD/MM/YYYY - DD/MM/YYYY"
                                            onChange={handleDateChange}
                                            format="dd/MM/yyyy"
                                            character=" - "
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
                                <div class="row mb-3 padd-0">
                                    <label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>Sales Rep</b></label>
                                    <div class="col-sm-9">
                                        <select value={salesRap} onChange={handleOptionChange} className="form-control selectinput" name="" id="" >
                                            <option value="">Select Sales Rep</option>
                                            {salesRapOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.key}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
                                <button type="button" onClick={searchFormData} className="acceptbtn" style={{ float: 'left' }} >Submit</button>
                            </div>
                            <div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
                                <button type="button" onClick={exportToExcel} className="acceptbtn" style={{ float: 'left' }} >Export to Excel</button>
                            </div>
                        </div>
                        <div className="verical-scrl-wrapper common-table autoheight-table dsr-report" id="style-3">
                            <table className="table table-bordered dataTable resizable" ref={locationReportRef}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Customer Name(A/C)</th>
                                        <th>Ordered / Visit</th>
                                        <th>Order Value</th>
                                        <th>Amt Collected</th>
                                        <th>Pay Mode</th>
                                        <th>Reason</th>
                                        <th>Sales Rep</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default DsrReport;