import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import Select from 'react-select';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Swal from 'sweetalert2';
import config from "../common/Config";

const RejectSales = () =>{

	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [selectedRole, setSelectedRole] = useState('');
	const [rejectedHistory, setRejectedHistory] = useState([]);
	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(50);

	const accessToken = localStorage.getItem('accessToken');


	const handleSubmit = (e) => {
	    e.preventDefault();
	   	fetchRejectedSalesData();
	};

	const fetchRejectedSalesData = async () => {
	    const formData = new FormData();
	    formData.append('start_date', fromDate);
	    formData.append('end_date', toDate);
	    formData.append('draw', draw);
	    formData.append('start', start);
	    formData.append('length', length);
	    formData.append('role', selectedRole);
	    formData.append('authorization', `Bearer ${accessToken}`);
	  

    	const apiUrl = `${config.API_URL}/get_reject_sales`;
	    try {
	      const response = await fetch(apiUrl, {
	        method: 'POST',
	        headers: {
	          // 'Content-Type': 'application/json',
	          authorization: `Bearer ${accessToken}`,
	        },
	        body: formData,
	      });

	      if (!response.ok) {
	        throw new Error('Failed to fetch new sales data');
	      }

	      const data = await response.json();
	      setRejectedHistory(data.data);
	      console.log(data.data);
	     
	    } catch (error) {
	      console.error('Error fetching new sales data:', error);
	    }
  	};

	useEffect(() => {
    
	    if (accessToken === null) {
	      window.location.href = `${config.BASE_URL}/login`;
	      return;
	    }

    	fetchRejectedSalesData();
  	}, [fromDate, toDate, selectedRole]);

	useEffect(() => {
    	const table = $('#rejectedSalesTable').DataTable({
		    pageLength: 50,
		    paging: true,
		    lengthMenu: [10, 20, 50],
		    searching: true,
		    ordering: true,
		    info: true,
		    responsive: true,
		    autoWidth: false,
      		language: {
        		paginate: {
          			first: 'First',
          			last: 'Last',
          			next: 'Next',
          			previous: 'Previous',
        		},
        		search: 'Search:',
      		},
      		data: rejectedHistory,
      		columns: [
	        	{ data: 'date' },
	        	{
	          		data: 'reference_no',
	          		render: function (data, type, row) {
	              		return '<a href="'+config.BASE_URL+'/order_summary/print/' + row.id + '">' + row.reference_no + '</a>';
	          		}
	        	},
		        { data: 'customer_with_account' },
		        { data: 'grand_total' },
		        { data: 'sale_person' },
		        { data: 'sale_status' },
       		],
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [rejectedHistory]);


	return(
		<>
			<Header />

      		<div class="breadcrumbs">
			  	<div class="container-fluid">
			    	<div class="row">
			      		<div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span class="last-crumb">Rejected Sales</span> 
			      		</div>
			    	</div>
			  	</div>
			</div>
			<div className="main-container">
        		<div className="container-fluid">
          			<div className="row">
            			<div className="col-12 col-lg-12 col-sm-12">
              				
			                <div className="col-md-12 col-lg-12 col-xs-12">
			                  	<div className="col-md-12 col-xs-12">


								  <div className="row">
						
						<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>From</b></label>
								<div class="col-sm-9">
								<input
							                        type="date"
							                        name="fromDate"
							                        value={fromDate}
							                        onChange={(e) => setFromDate(e.target.value)}
							                        className="form-control"/>
								</div>
							</div>

						</div>


						<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left'}}>

							<div class="row mb-3 padd-0">
								<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>To</b></label>
								<div class="col-sm-9">
								<input
							                        type="date"
							                        name="toDate"
							                        value={toDate}
							                        onChange={(e) => setToDate(e.target.value)}
							                        className="form-control"
					                      		/>
								</div>
							</div>


						</div>

						
						<div className="col-md-2 col-lg-2 col-sm-12">
						<button type="submit" onClick={handleSubmit}  className="acceptbtn" style={{ float: 'left'}}>Submit</button>
						</div>

						

					

				</div>




			                  		
								</div>
							</div>

							<div class="total-manifests-area dashboard">
                				<div className="col-md-12 col-lg-12 col-xs-12">

                					<div className="verical-scrl-wrapper reject-sales" id="style-3">
					                    <table className="table table-bordered dataTable resizable" id="rejectedSalesTable">
					                      	<thead>
					                        	<tr>
					                          		<th>Order Date</th>
					                          		<th>Order Id</th>
					                          		<th>Customers</th>
					                          		<th>Order Amount</th>
					                          		<th>Sales Person Name</th>
					                          		<th style={{width: '120px'}}>Action</th>
					                        	</tr>
					                      	</thead>
					                      	<tbody>
					                        
					                      	</tbody>
					                    </table>
					                </div>


                				</div>

                			</div>


						</div>
					</div>
				</div>
			</div>




			<Footer />


		</>

	);
}
export default RejectSales;