import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table
import generateOrderSummaryPDF from './generateOrderSummaryPDF';
import generateOrderSummaryPDF_tsc from './generateOrderSummaryPDF_tsc';
import generateOrderSummaryPDF_knm from './generateOrderSummaryPDF_knm';

const OrderSummaryPrint = (props) => {

  const { orderId } = useParams();
  const [shopDetails, setShopDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [totalVal, setTotalVal] = useState(
    {
      totalstd: 0,
      totalzeroGoods: 0,
      totalTax: 0,
      orderDiscount: 0,
      over_all_discount:0,
      grandTotal: 0,
    }
  );

  const accessToken = localStorage.getItem('accessToken');

  const fetchInvoice = async () => {
    const formData = new FormData();
    formData.append('orderId', orderId);
    formData.append('authorization', `Bearer ${accessToken}`);
    const apiUrl = `${API_URL}/ws-order-history-details`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Not Order Report');
      }
      const data = await response.json();
      //console.log(data.details.shop_details);
      setShopDetails(data.details.shop_details);
      setOrderDetails(data.details.orderDetails[0]);
      setProductDetails(data.details.product_details);



      // Calculate total standard goods, zero-rated goods, tax, and other totals
      
      

      const totalstd = data.details.product_details
      .filter(item => item.item_tax != '0.00' && item.item_tax != null)
      .reduce((sum, item) => {
        if (data.details.orderDetails[0].pos == 0) {
          return sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty));
        } else {
          return sum + parseFloat(item.subtotal);
        }
      }, 0);  // Initialize the sum as 0



      const totalzeroGoods = data.details.product_details
      .filter(item => item.item_tax == '0.00' || item.item_tax === null)
      .reduce((sum, item) => {
        if (data.details.orderDetails[0].pos == 0) {
          return sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty));
        } else {
          return sum + parseFloat(item.subtotal);
        }
      }, 0);
    
    console.log(totalzeroGoods);



      const totalTax = data.details.product_details
        .reduce((sum, item) => {
          if (parseFloat(item.tax_rate_percent) !== 0.00) {
            //  const vatAmount = (parseFloat(item.unit_price) * parseFloat(item.order_qty)) * (parseFloat(item.tax_rate_percent) / 100);
            const vatAmount = parseFloat(item.item_tax);
            return sum + vatAmount;
          }
          return sum;
        }, 0);

      // Calculate order discounts
      const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
      const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;
      const over_all_amount = parseFloat(data.details.orderDetails[0].over_all_amount) || 0;
      const over_all_discount = parseFloat(data.details.orderDetails[0].over_all_discount) || 0;
      const pos = parseFloat(data.details.orderDetails[0].pos) || 0;
      
      // Calculate grand total
      const grandTotal = totalstd + totalzeroGoods + totalTax - orderDiscount;



      setTotalVal({
        totalstd,
        totalzeroGoods,
        totalTax,
        orderDiscount,
        orderDiscount1,
        over_all_amount,
        over_all_discount,
        grandTotal,
        pos
      });


    } catch (error) {
      console.error('api fail Error fetching Not Order Report:', error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchInvoice();
  }, []);

  useEffect(() => {

    const filteredProductDetails = productDetails.filter(item => parseFloat(item.order_qty) > 0);

    const table = $('#invoicePrint').DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: filteredProductDetails,
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.product_code} - ${row.product_name}- ${row.size}</span> `;
            } else {
              return `<span >${row.product_code} - ${row.product_name}</span> `;
            }
          }
        },
        {
          data: null,
          className: 'text-center',
          width: '10%',
          render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${parseInt(row.order_qty)}</span>`;
            } else {
              return `<span>${parseInt(row.order_qty)}</span>`;
            }
          }
        },
        {
          data: null,
          className: 'text-center',
          width: '10%',
          render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.order_type}</span>`;
            } else {
              return `<span>${row.order_type}</span>`;
            }
          }
        },
        {
          data: 'unit_price',
          className: 'text-end',
          width: '10%',
          render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return row.unit_price ? `<span style="color: #f14240;">£ ${row.unit_price}</span>` : `<span>£ 0.00</span>`;
            } else {
              return row.unit_price ? `<span>£ ${row.unit_price}</span>` : `<span>£ 0.00</span>`;
            }
          }
        },
        {
          data: 'item_discount',
          className: 'text-end',
          width: '10%',
          render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return row.item_discount ? `<span style="color: #f14240;">£ ${row.item_discount}</span>` : `<span>£ 0.00</span>`;
            } else {
              return row.item_discount ? `<span>£ ${row.item_discount}</span>` : `<span>£ 0.00</span>`;
            }
          }
        },
        {
          data: 'item_tax',
          className: 'text-end',
          width: '10%',
          render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return row.item_tax ? `<span style="color: #f14240;">£ ${row.item_tax}</span>` : `<span>0.00</span>`;
            } else {
              return row.item_tax ? `<span>£ ${row.item_tax}</span>` : `<span>0.00</span>`;
            }

          }
        },
        {
          data: 'subtotal',
          className: 'text-end',
          width: '12%',
          render: function (data, type, row)
           {
            if(totalVal.pos == 0)
            {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return row.subtotal ? `<span style="color: #f14240;">£ ${parseFloat(row.unit_price * row.order_qty).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
            } else {
              return row.subtotal ? `<span>£ ${parseFloat(row.unit_price * row.order_qty).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
            }
          }else
          {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return row.subtotal ? `<span style="color: #f14240;">£ ${parseFloat(row.subtotal).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
            } else {
              return row.subtotal ? `<span>£ ${parseFloat(row.subtotal).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
            }
          }
        }
        },

      ],
      columnDefs: [
        {
          targets: [0],
          orderable: false
        }
      ],
      drawCallback: function (settings) {
        var api = this.api();
        $('tfoot').empty();
        let grand_total1;
        let add;
        let orderdisc;
        if (totalVal.pos == 0) {
          grand_total1 = (totalVal.grandTotal - (totalVal.grandTotal * (totalVal.orderDiscount1 / 100)) - totalVal.over_all_amount).toFixed(2);
          orderdisc = totalVal?.orderDiscount;
        } else {
          add = totalVal.totalstd + totalVal.totalzeroGoods + totalVal.totalTax;
          orderdisc = (add * (totalVal.orderDiscount1 / 100)).toFixed(2);
          grand_total1 = (add - (add * (totalVal.orderDiscount1 / 100))).toFixed(2);
        }
        // Create footer rows with totals
        const footerRows = [
          {
            colSpan: 6,
            label: 'Total Std Goods',
            value: `£ ${totalVal.totalstd.toFixed(2)}`
          },
          {
            colSpan: 6,
            label: 'VAT Amount',
            value: `£ ${totalVal.totalTax.toFixed(2)}`
          },
          {
            colSpan: 6,
            label: 'Total Zero Goods',
            value: `£ ${totalVal.totalzeroGoods.toFixed(2)}`
          },
          {
            colSpan: 6,
            label: 'Promotion Discount',
            value: `£ ${totalVal?.orderDiscount?.toFixed(2) || '0.00'}` // Null-safe chaining with fallback
          },
         {
  colSpan: 6,
  label: `Order Discount (${totalVal?.over_all_discount?.toFixed(2) || '0.00'}%)`,
  value: `£ ${totalVal?.over_all_amount?.toFixed(2) || '0.00'}` // Ensure totalVal and value existence
},
          {
            colSpan: 6,
            label: 'Invoice Total',
            value: `£ ${totalVal?.grandTotal && totalVal?.orderDiscount1 !== undefined
              ? grand_total1
              : '0.00'
            }`
          }
        ];


        footerRows.forEach(row => {
          const footerRow = $('<tr>')
            .append(`<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`)
            .append(`<td style="text-align : right;" ><span>${row.value}</span></td>`);
          $('tfoot').append(footerRow);
        });
      }
    });
    return () => {
      table.destroy();
    };
  }, [productDetails, totalVal]);


  const handleGenerateDeliveryNotePDF = (e, orderId) => {
    if (accessToken == 'tsc' || accessToken == 'tss') {
      generateOrderSummaryPDF_tsc(orderId);
    } else if (accessToken == 'knm') {
      generateOrderSummaryPDF_knm(orderId);
    } else {

      generateOrderSummaryPDF(orderId);
    }
  }

  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>

              <span className="last-crumb">Order Summary </span>
            </div>
          </div>
        </div>
      </div>


      <div className="main-container">
      <div className="container-fluid">

      {/*<div className="row">
                <div class="col-12">
                  <button onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)} className='acceptbtn'>Print Invoice</button>

                </div>

              </div>*/}
      <div className="row">
        <div className="col-12">
          <div className="show-order-area dashboard">
            <div className="sales-view-area">
              

              <div className="row page-header" style={{ paddingTop: '10px' }}>
                <div className="col-7">
                  <p>
                    <span>{orderDetails.company}</span><br />{orderDetails.address}<br />
                    {orderDetails.city}, {orderDetails.postal_code}, {orderDetails.country}<br />

                  </p>
                  <p>
                    <span>Tel : {orderDetails.phone}</span>
                  </p>
                  <p>
                    <span>Email : {orderDetails.email}</span>
                  </p>
                  <p>
                    <span>Customer A/C Number: {orderDetails.account_no}</span>
                  </p>
                </div>
                <div className="col-5">
                  <ul>
                    <li><span>Order :</span>  INV{orderDetails.reference_no}</li>
                    <li><span>Order Date :</span> {orderDetails.date}</li>
                    <li><span>Sale Status:</span> {orderDetails.sale_status}</li>
                    <li><span>Route Number :</span> {orderDetails.route_number}</li>
                    <li><span>Payment Status :</span> {orderDetails.payment_status}</li>
                  </ul>
                </div>
              </div>

              <div className="order-summery-invoice-tbl">
                <table className="table table-bordered resizable" id="invoicePrint">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#e28483' }} >Description</th>
                      <th style={{ backgroundColor: '#e28483' }} >Order Quantity</th>
                      <th style={{ backgroundColor: '#e28483' }} >Type</th>
                      <th style={{ backgroundColor: '#e28483' }} >Unit Price</th>
                      <th style={{ backgroundColor: '#e28483' }} >Discount</th>
                      <th style={{ backgroundColor: '#e28483' }} >VAT</th>
                      <th style={{ backgroundColor: '#e28483' }} >Net Amount</th>
                    </tr>
                  </thead>
                  <tbody>

                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Std Goods</span>
                      </td>
                      <td className="text-end"><span>£ </span></td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">VAT Amount</span>
                      </td>
                      <td><span>£ </span></td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Zero Goods</span>
                      </td>
                      <td><span>0.00</span></td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Order Discount</span>
                      </td>
                      <td><span>0.00</span></td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Amount</span>
                      </td>
                      <td><span>£ </span></td>
                    </tr>
                  </tfoot>
                </table>
                <p style={{ textAlign: 'center' }}>No claims for damages or short delivery will be considered unless made at the time of delivery of goods.These goods are not sold on a sale or return basis.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

</div>
</div>


      <Footer />
    </>
  );
}

export default OrderSummaryPrint;
