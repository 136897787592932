import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from './Config';
import generateTripSummaryPDF from "./TripSummaryPDF";

const ClosedTripSummary= () => {
	const { tripId } = useParams();

	const [selectedRole, setSelectedRole] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]); // State to store statistics data
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [tripNumber, setTripNumber] = useState([]);
  const [driverName, setDriverName] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [date, setDate] = useState([]);
  const [PartialUndelivered, setPartialUndelivered] = useState([]);


  const [totalProductsBox, setTotalProductsBox] = useState([]);
  const [totalProductsPiece, setTotalProductsPiece] = useState([]);
  const [totalBoxPrice, setTotalBoxPrice] = useState([]);
  const [totalPiecePrice, setTotalPiecePrice] = useState([]);
  const [totalProductsPrice, setTotalProductsPrice] = useState([]);

  const [deliveredProductsBox, setDeliveredProductsBox] = useState([]);
  const [deliveredProductsPices, setDeliveredProductsPices] = useState([]);
  const [deliveredBoxPrice, setDeliveredBoxPrice] = useState([]);
  const [deliveredPiecePrice, setDeliveredPiecePrice] = useState([]);
  const [TotaldeliveredPrice, setTotalDeliveredPrice] = useState([]);


  const [undeliveredProductsBox, setUndeliveredProductsBox] = useState([]);
  const [undeliveredProductsPices, setUndeliveredProductsPices] = useState([]);
  const [undeliveredBoxPrice, setUndeliveredBoxPrice] = useState([]);
  const [undeliveredPiecePrice, setUndeliveredPiecePrice] = useState([]);
  const [totalUndeliveredPrice, setTotalUndeliveredPrice] = useState([]);


  const [returnProductsBox, setReturnProductsBox] = useState([]);
  const [returnProductsPices, setReturnProductsPices] = useState([]);
  const [returnBoxPrice, setReturnBoxPrice] = useState([]);
  const [returnPiecePrice, setReturnPiecePrice] = useState([]);
  const [totalReturnPrice, setTotalReturnPrice] = useState([]);




  const [TripOrder, setTripOrder] = useState([]);
  const accessToken = localStorage.getItem('accessToken'); 

  const handleSubmit = (e) => {
  	e.preventDefault();
  	fetchData();
  };
const handleGeneratePDF = (e, tripId) => 
 {
    
     generateTripSummaryPDF(tripId);
   
  };
  const fetchData = async () => {
  	const formData = new FormData();
  	formData.append('tripId', tripId);
  	const apiUrl = `${API_URL}/get_trip_summary_details`;
  	try {
  		const response = await fetch(apiUrl, {
  			method: 'POST',
  			headers: {
	          // 'Content-Type': 'application/json',
  				authorization: `Bearer ${accessToken}`,
  			},
  			body: formData,
  		});

  		if (!response.ok) {
  			throw new Error('Failed to fetch new sales data');
  		}

  		const data = await response.json();
  		const tripData = data.data;
	     // alert(tripData.trip_number)
	      // console.log(tripData.trip_order_details);


  		setTripNumber(tripData.trip_number);
  		setDriverName(tripData.driver);
  		setVehicle(tripData.vehicle);
  		setDate(tripData.date);

	      //total Product
  		setTotalProductsBox(tripData.total_products_box);
  		setTotalProductsPiece(tripData.total_products_piece);
  		setTotalBoxPrice(parseFloat(tripData.total_box_price).toFixed(2));
  		setTotalPiecePrice(parseFloat(tripData.total_piece_price).toFixed(2));
  		setTotalProductsPrice(parseFloat(tripData.total_piece_price+tripData.total_box_price).toFixed(2));

	      //Delivered Product 
  		setDeliveredProductsBox(tripData.total_delivered_box);
  		setDeliveredProductsPices(tripData.total_delivered_piece);
  		setDeliveredBoxPrice(parseFloat(tripData.total_delivered_box_price).toFixed(2));
  		setDeliveredPiecePrice(parseFloat(tripData.total_delivered_piece_price).toFixed(2));
  		setTotalDeliveredPrice(parseFloat(tripData.total_delivered_box_price+tripData.total_delivered_piece_price).toFixed(2));

 				//Undelivered Product
  		setUndeliveredProductsBox(tripData.undelivered_products_box);
  		setUndeliveredProductsPices(tripData.undelivered_products_piece);
  		setUndeliveredBoxPrice(parseFloat(tripData.undelivered_box_price).toFixed(2));
  		setUndeliveredPiecePrice(parseFloat(tripData.undelivered_piece_price).toFixed(2));
  		setTotalUndeliveredPrice(parseFloat(tripData.undelivered_box_price+tripData.undelivered_piece_price).toFixed(2));

			//Undelivered Product
  		setReturnProductsBox(tripData.return_products_box);
  		setReturnProductsPices(tripData.return_products_piece);
  		setReturnBoxPrice(parseFloat(tripData.return_box_price).toFixed(2));
  		setReturnPiecePrice(parseFloat(tripData.return_piece_price).toFixed(2));
  		setTotalReturnPrice(parseFloat(tripData.return_box_price+tripData.return_piece_price).toFixed(2));

  		setTripOrder(tripData.trip_order);
  		setPartialUndelivered(tripData.partial_undelivered);
  		
	      //console.log(tripData.undelivered_products_list);
  	} catch (error) {
  		console.error('Error fetching new sales data:', error);
  	}
  };

  useEffect(() => {
  	fetchData();
  }, []);
let totalOrders = 0;
let totalAmount = 0;
let totalCollect = 0;
let cash = 0;
let cheque = 0;
let card = 0;
let totalOrders2 = 0;
let not_delivered_qty = 0;

  return (
  	<div>
  	<Header />

  	<div className="breadcrumbs">
  	<div className="container-fluid">
  	<div className="row">
  	<div className="col-12">
  	<a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Dashboard</a> &gt; <span className="last-crumb">Close Trip Summary</span>
  	</div>
  	</div>
  	</div>
  	</div>

  	<div class="main-container">
  	<div class="container-fluid">
  	<div class="row">
  	<div class="col-12 col-lg-12 col-sm-12">
  	<div class="show-addprod-area dashboard">
  	<div class="complete-summery-area">
  	<div class="title"> <span> Trip Summary - {tripNumber} </span>
  	<ul>
  	<li>Date - {date}</li>
  	<li>Driver - {driverName}</li>
  	<li>Vehicle - {vehicle}</li>
  	 <button onClick={(e) => handleGeneratePDF(e, tripId)} className="noopener acceptbtn">
                Print 
              </button>
 
  	</ul>
  	</div>
  	</div>
  	<div class=" float:left; width:100%;">
  	<div class="row">
		<div class="col-12 col-lg-3 col-sm-6">
		<div class="sumery-delivery-box">
				<table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
					<tbody>
						<tr>
							<td colSpan='2' style={{ textAlign: 'center' }}>Goods Taken</td>
						</tr>
		<tr>
		<td>{totalProductsBox}  box</td>
		<td>£ {totalBoxPrice}</td>

		</tr>
		<tr>
		<td>{totalProductsPiece} Psc</td>
		<td>£ {totalPiecePrice}</td>

		</tr>
		<tr>
		<td>Total</td>
		<td>£ {(totalProductsPrice ?? 0)}</td>

		</tr>

		</tbody>

		</table>
		</div>
		</div>
  	<div class="col-12 col-lg-3 col-sm-6">
  	<div class="sumery-delivery-box">
  	<table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
  	<tbody>
  	<tr>
  	<td colSpan='2' style={{ textAlign: 'center' }}>Goods Delivered</td>

  	</tr>
  	<tr>
  	<td>{deliveredProductsBox}  box</td>
  	<td>£ {deliveredBoxPrice}</td>

  	</tr>
  	<tr>
  	<td>{deliveredProductsPices}  Psc</td>
  	<td>£ {deliveredPiecePrice}</td>

  	</tr>
  	<tr>
  	<td>Total</td>
  	<td>£ {TotaldeliveredPrice}</td>

  	</tr>

  	</tbody>

  	</table>
  	</div>
  	</div>
  	<div class="col-12 col-lg-3 col-sm-6">
  	<div class="sumery-delivery-box">
  	<table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
  	<tbody>
  	<tr>
  	<td colSpan='2' style={{ textAlign: 'center' }}>Goods Undelivered</td>

  	</tr>
  	<tr>
  	<td>{undeliveredProductsBox} box</td>
  	<td>£ {undeliveredBoxPrice}</td>

  	</tr>
  	<tr>
  	<td>{undeliveredProductsPices} Psc</td>
  	<td>£ {undeliveredPiecePrice}</td>

  	</tr>
  	<tr>
  	<td>Total</td>
  	<td>£ {totalUndeliveredPrice}</td>

  	</tr>

  	</tbody>

  	</table>
  	</div>
  	</div>
  	<div class="col-12 col-lg-3 col-sm-6">
  	<div class="sumery-delivery-box">
  	<table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered resizable">
  	<tbody>
  	<tr>
  	<td colSpan='2' style={{ textAlign: 'center' }}>Goods Return</td>

  	</tr>
  	<tr>
  	<td>{returnProductsBox}  box</td>
  	<td>£ {returnBoxPrice}</td>

  	</tr>
  	<tr>
  	<td>{returnProductsPices} Psc</td>
  	<td>£ {returnPiecePrice}</td>

  	</tr>
  	<tr>
  	<td>Total</td>
  	<td>£ {totalReturnPrice}</td>

  	</tr>

  	</tbody>
  	</table>
  	</div>
  	</div>
  	</div>
  	</div>
  	</div>

	
  	<div class="total-manifests-area">
  	<b>Order Details</b>
  	<div class="verical-scrl-wrapper common-table autoheight-table closed-trip-summery" id="style-3"> 
  	<table class="table table-bordered resizable">
  	<thead><tr>
  	<th>#</th>
  	<th>Customers (A/C No)</th>
  	<th>Order ID	</th>
  	<th>Credit Type	</th>
  	<th>Inv. Amt	</th>
  	<th>Previous Dues	</th>
  	<th>Total Dues	</th>
  	<th>Collected</th>
  	<th>Type</th>
  	</tr>
  	</thead>
<tbody>
    {TripOrder.map((item, index) => {

      totalOrders += 1;
      totalAmount += parseFloat(item.grand_total) || 0;
      totalCollect += parseFloat(item.driver_collect) || 0;

      if (item.type === 'cash') {
        cash += parseFloat(item.driver_collect) || 0;
      }
      if (item.type === 'card') {
        card += parseFloat(item.driver_collect) || 0;
      }
      if (item.type === 'cheque') {
        cheque += parseFloat(item.driver_collect) || 0;
      }

      return (
        <tr key={item.reference_no}>
          <td>{index + 1}</td>
          <td>{`${item.customerName} (${item.customerAccountNo})`}</td>
          <td>{item.reference_no}</td>
          <td>{item.credit_type_name}</td>
          <td style={{ textAlign: 'right' }}>£ {parseFloat(item.grand_total).toFixed(2)}</td>
          <td>£ {parseFloat(item.previous_dues).toFixed(2)}</td>
          <td>£ {parseFloat(item.total_dev).toFixed(2)}</td>
          <td>£ {parseFloat(item.driver_collect).toFixed(2)}</td>
          <td>{item.type}</td>
        </tr>
      );
    })}
  </tbody>

  <tfoot>
    <tr>
      <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>
       
      
       Cash: £{cash.toFixed(2)}&nbsp;&nbsp;&nbsp;Card: £{card.toFixed(2)}&nbsp;&nbsp;&nbsp;Cheque: £{cheque.toFixed(2)}
      </td>
      <td colSpan="2" style={{ textAlign: 'left', fontWeight: 'bold' }}>
        <span>Total Amount: </span>
      </td>
      <td>
        £ {totalAmount.toFixed(2)}
      </td>
      <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Collect:</td>
      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
        £ {totalCollect.toFixed(2)}
      </td>
    </tr>
    </tfoot>
  	</table>
  	</div>

  	<div class="total-manifests-area">
  	<b>Undelivered Product List</b>
  	<div class="verical-scrl-wrapper common-table autoheight-table closed-trip-summery_products" id="style-3"> 
<table className="table table-bordered resizable">
  <thead>
    <tr>
      <th>#</th>
      <th>Order Id</th>
      <th>Product Name</th>
      <th>Undeli.Qty</th>
      <th>Order Type</th>
      <th>Reason</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    {PartialUndelivered
      .filter(item => item.status != 'Return') // Only "Return" items
      .map((item, index) => (
        <tr key={item.reference_no}>
          <td>{index + 1}</td>
          <td>{item.reference_no}</td>
          <td>{`${item.productName} (${item.productCode})`}</td>
          <td>{item.not_delivered_qty}</td>
          <td>{item.order_type}</td>
          <td>{item.reason}</td>
          <td>{item.status}</td>
        </tr>
      ))}
  </tbody>

  <tfoot>
    <tr>
      <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>
        Total Products: {PartialUndelivered.filter(item => item.status != 'Return').length}
      </td>
      <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
        <span style={{ textAlign: 'center', fontWeight: 'bold' }}>Total Undelivered Qty: </span>
      </td>
      <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
        {PartialUndelivered
          .filter(item => item.status != 'Return')
          .reduce((sum, item) => sum + (parseFloat(item.not_delivered_qty) || 0), 0)}
      </td>
      <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
    </tr>
  </tfoot>
</table>
  	</div>
</div>
<div class="total-manifests-area">
  	<b>Return Product List</b>
  	<div class="verical-scrl-wrapper common-table autoheight-table closed-trip-summery_products" id="style-3"> 
 <table className="table table-bordered resizable">
  <thead>
    <tr>
      <th>#</th>
      <th>Order Id</th>
      <th>Product Name</th>
      <th>Undeli.Qty</th>
      <th>Order Type</th>
      <th>Reason</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    {PartialUndelivered
      .filter(item => item.status === 'Return') // Only "Return" items
      .map((item, index) => (
        <tr key={item.reference_no}>
          <td>{index + 1}</td>
          <td>{item.reference_no}</td>
          <td>{`${item.productName} (${item.productCode})`}</td>
          <td>{item.not_delivered_qty}</td>
          <td>{item.order_type}</td>
          <td>{item.reason}</td>
          <td>{item.status}</td>
        </tr>
      ))}
  </tbody>

  <tfoot>
    <tr>
      <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>
        Total Products: {PartialUndelivered.filter(item => item.status === 'Return').length}
      </td>
      <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
        <span style={{ textAlign: 'center', fontWeight: 'bold' }}>Total Undelivered Qty: </span>
      </td>
      <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
        {PartialUndelivered
          .filter(item => item.status === 'Return')
          .reduce((sum, item) => sum + (parseFloat(item.not_delivered_qty) || 0), 0)}
      </td>
      <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}></td>
    </tr>
  </tfoot>
</table>

  	</div>
</div>
  	</div>
  	</div>
  	</div>
  	</div>
  	</div>
  	<Footer />
  	</div>
  	);
  };

  export default ClosedTripSummary;
