import jsPDF from 'jspdf';
import 'jspdf-autotable';

const generatePaymentLedgerPDF = (paymentDetails, paymentLedger) => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    const title = 'Payment Ledger';
    const titleWidth = doc.getTextWidth(title);
    const pageWidth = doc.internal.pageSize.getWidth();
    const titleX = (pageWidth - titleWidth) / 2;
    doc.text(title, titleX, 10); // Adjust the y coordinate to move the title closer to the top

    // Add date range
    doc.setFontSize(15);
    doc.setFont('helvetica', 'bold');
    const dateRange = `Name : ${paymentDetails.name} | From Date: ${paymentDetails.startDate} To: ${paymentDetails.endDate}`;
    const dateRangeWidth = doc.getTextWidth(dateRange);
    const dateRangeX = (pageWidth - dateRangeWidth) / 2;
    doc.text(dateRange, dateRangeX, 20); // Adjust the y coordinate to move the date range closer to the title

    // Add table
    const tableColumn = ["Date", "Customer (A/C No)", "Amount", "Payment Mode"];
    const tableRows = [];

    paymentLedger.forEach(item => {
        const paymentData = [
            item.date,
            `${item.name} (${item.account_no})`,
            `£${parseFloat(item.collected_amount).toFixed(2)}`,
            item.payment_mode
        ];
        tableRows.push(paymentData);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30, // Adjust the startY value to move the table closer to the top
        margin: { top: 10, left: 10, right: 10 }, // Set top, left, and right margins
        styles: {
            lineWidth: 0.1,
            lineColor: [0, 0, 0]
        },
        columnStyles: {
            2: { halign: 'right' } // Align the "Amount" column to the right
        },
        headStyles: {
            fillColor: [255, 255, 255], // Set background color to white
            fontStyle: 'bold',
            textColor: [0, 0, 0]
        }
    });

    // Add total collected
    const totalCollect = paymentLedger.reduce((acc, item) => acc + parseFloat(item.collected_amount), 0).toFixed(2);
    const cash = paymentLedger.filter(item => item.payment_mode === 'cash').reduce((acc, item) => acc + parseFloat(item.collected_amount), 0).toFixed(2);
    const cheque = paymentLedger.filter(item => item.payment_mode === 'cheque').reduce((acc, item) => acc + parseFloat(item.collected_amount), 0).toFixed(2);
    const card = paymentLedger.filter(item => item.payment_mode === 'card').reduce((acc, item) => acc + parseFloat(item.collected_amount), 0).toFixed(2);

    doc.text(`Total Collected: £${totalCollect} | Cash: £${cash} | Cheque: £${cheque} | Card: £${card}`, 14, doc.autoTable.previous.finalY + 10);

    // Save the PDF
    doc.save('payment_ledger.pdf');
};

export default generatePaymentLedgerPDF;