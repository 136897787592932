import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../common/Config";
import Swal from "sweetalert2";

const ShopifyCategories = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [searchValue, setSearchValue] = useState("");
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const accessToken = localStorage.getItem("accessToken");
  const [catFormData, setCatFormData] = useState({
    category_code: "",
    category_name: "",
    sort_number: "",
    slug: "",
    description: "",
  });

  const [catEditData, setCatEditData] = useState({
    category_code: "",
    category_name: "",
    sort_number: "",
    slug: "",
    description: "",
  });

  const handleCatChange = (e) => {
    const { name, value } = e.target;
    if (name === "category_code" && value < 0) {
      return;
    }
    if (name === "sort_number" && value < 0) {
      return;
    }
    setCatFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCatEditChange = (e) => {
    const { name, value } = e.target;
    if (name === "category_code" && value < 0) {
      return;
    }

    if (name === "sort_number" && value < 0) {
      return;
    }

    setCatEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const modalRef = useRef(null);
  const handleCategorySubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("category_code", catFormData.category_code);
    formData.append("category_name", catFormData.category_name);
    formData.append("sort_number", catFormData.sort_number);
    formData.append("slug", catFormData.slug);
    formData.append("description", catFormData.description);

    const apiUrl = `${config.API_URL}/add_product_categories`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to add category");
      }

      const data = await response.json();

      const msg = data.message;
      if (data.success == true) {
        Swal.fire({
          title: "Success!",
          text: "Category added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
          bsModal.hide();

          setCatFormData({
            category_code: "",
            category_name: "",
            sort_number: "",
            slug: "",
            description: "",
          });

          fetchProductCategoryList();
        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: msg,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleCategoryUpdate = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("category_code", catEditData.category_code);
    formData.append("category_name", catEditData.category_name);
    formData.append("sort_number", catEditData.sort_number);
    formData.append("slug", catEditData.slug);
    formData.append("description", catEditData.description);
    formData.append("catid", catEditData.catid);

    const apiUrl = `${config.API_URL}/edit_product_categories`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to update category");
      }

      const data = await response.json();
      console.log(data);
      const msg = data.message;
      if (data.success) {
        Swal.fire({
          title: "Success",
          text: "Category updated successfully.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: msg,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }

      setCatEditData({
        category_code: "",
        category_name: "",
        sort_number: "",
        slug: "",
        description: "",
        catid: "",
      });

      fetchProductCategoryList();
    } catch (error) {
      console.error("Error adding category:", error);
      // Handle error (e.g., show an error message)
    }
  };


  const fetchProductCategoryList = async () => {
    const pdtCatForm = new FormData();

    pdtCatForm.append("draw", draw);
    pdtCatForm.append("start", start);
    pdtCatForm.append("length", length);

    const apiPdtCatUrl = `${config.API_URL}/get_product_categories`;
    try {
      const response = await fetch(apiPdtCatUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: pdtCatForm,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch product category");
      }

      const data = await response.json();

      console.log(data);
      setProductCategoryList(data.data);
    } catch (error) {
      console.log("Error fetch product category", error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    fetchProductCategoryList();
  }, [accessToken]);

  const handleEditClick = (rowData) => {
    setCatEditData({
      category_code: rowData.code,
      category_name: rowData.name,
      sort_number: rowData.sort_number,
      slug: rowData.slug,
      description: rowData.description,
      catid: rowData.id,
    });
  };

  useEffect(() => {
    const table = $("#pdtCategoryTable").DataTable({
      pageLength: 10,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: productCategoryList,
      columns: [
        {
          data: null,
          width: "5%",
          className: "text-center",
          render: function (data, type, row) {
            return `<div class="radio-tbl"><label class="checkbox"><input type="checkbox" class="checkbox multi-select input-xs" name="val[]" value="${row.id}"/><span class="default"></span></label></div>`;
          },
        },
        { data: "code", className: "text-start" },
        { data: "name", className: "text-start" },
      ],
      columnDefs: [],
    });

    return () => {
      table.destroy();
    };
  }, [productCategoryList]);

  const handleProductsSubmit = async () => {
    const formData = new FormData();
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${config.API_URL}/shopify/submit-products-to-shopify`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit selection");
      }

      const data = await response.json();

      if (data.status === "true") {
        Swal.fire({
          title: "Success!",
          text: "Products Created successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error submitting selection:", error);
    }
  };



  // Handle button click to submit selected orders and picker to another API
  const handleSelectionSubmit = async (event) => {
    event.preventDefault(); 
    const formData = new FormData(event.target);
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/shopify/create-collections`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit selection");
      }

      const data = await response.json();

      if (data.status === "true") {
        Swal.fire({
          title: "Success!",
          text: "Category Created successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error submitting selection:", error);
    }
  };

  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i class="fa fa-home"></i>
              </a>{" "}
              &gt;
              <a href="Dashboard">Master</a> &gt;
              <span class="last-crumb">Product Category</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: "relative" }}>
                <div>
                  <form id="picking_create" onSubmit={handleSelectionSubmit}>
                    <div className="dashform">
                      <div style={{ float: "right" }}>
                        <button type="submit" className="multisubmit">
                          Submit
                        </button>
                      </div>
                    </div>
            <a onClick={handleProductsSubmit} className="btn btn-primary">Insert All Products</a>
                    <div className="show-order-area dashboard">
                      
                        <table
                          className="table table-bordered dataTable resizable"
                          id="pdtCategoryTable"
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>#</th>
                              <th>Category Code</th>
                              <th>Category Name</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShopifyCategories;
