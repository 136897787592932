import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";


const CompleteTrips = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [completeTrips, setCompleteTrips] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');
   const salesOrderRef = useRef(null);


  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchData();
  }, [fromDate, toDate]);




  const fetchData = (startDate, endDate) => {
    const $table = $(salesOrderRef.current);
    if (!$table.length) return;

    // Destroy existing DataTable instance to prevent reinitialization issues
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    // Initialize DataTable
    $table.DataTable({
      processing: true,
      serverSide: true,
      pageLength: 50,
      ajax: {
        url: `${config.API_URL}/get_complete_trip_list`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return {
            ...d,
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchByFromdate: startDate,
            searchByTodate: endDate,
          };
        },
        dataSrc: function (json) {
          if (json?.data && Array.isArray(json.data)) {
            setCompleteTrips(json.data);
            return json.data;
          }
          return [];
        },
        error: function (xhr) {
          console.error("DataTable AJAX error:", xhr.responseText);
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        { data: null },
        { data: 'date' },
        { data: 'trip_number',
          render: function (data, type, row) {
              return '<a href="'+BASE_URL+'/complete_trips/closed_trip_summary/' + row.id + '">' + row.trip_number + '</a>';
          }
        },
        { data: 'driver' },
        { data: 'vehicle' , className: "text-center" },
        { data: 'route_number' },
        { 
          data: null,
          render: function (data, type, row) {
                return '<span class="btn btn-primary badge " >'  + row.status + '</span>';
          }
        },
        ],
        columnDefs: [
          { 
              targets: [0,1,2,3,4,5], 
              sortable: false 
          }],
          drawCallback: function (settings) {
              var api = this.api();
              var startIndex = api.page.info().start;
              api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
                  cell.innerHTML = startIndex + i + 1;
              });
          }
    });



    // Cleanup on unmount
    return () => {
      if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
      }
    };
  };



  return (
    <div>
      <Header />
      <div className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-12 col-sm-12"> 
                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
                  <Link to="/CompleteTrips">Closed Trips</Link> 
                </div>
            </div>
          </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                
                <div className="verical-scrl-wrapper complete-trips-area" id="style-3" style={{marginTop: '0px', width : '100%'}} >
                  <table className="table table-bordered dataTable resizable" ref={salesOrderRef}>
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>Date</th>
                        <th>Trip ID</th>
                        <th>Driver</th>
                        <th>Vehicle</th>
                         <th>Route</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CompleteTrips;
