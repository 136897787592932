import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { API_URL } from '../Config';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';
import generatePaymentLedgerPDF from "./PaymentLadgerPDF";

const PaymentLadger = () => {
    const accessToken = localStorage.getItem('accessToken');
    const [dates, setDates] = useState([null, null]);
    const [reportrange, setReportrange] = useState('');

    const [salesRapOptions, setSalesRapOptions] = useState([]);
    const [salesRap, setSalesRap] = useState('');
    const [paymentDetails, setPaymentDetails] = useState({
        name: '',
        startDate: '',
        endDate: ''
    });
    const [paymentLedger, setPaymentLedger] = useState([]);
    const [nodataRow, setNodataRow] = useState('');

    const [userOptions, setUserOptions] = useState([]);
    const [userFilteredOptions, setUserFilteredOptions] = useState([]);
    const [userValue, setUserValue] = useState(null);
    const [userInputValue, setUserInputValue] = useState('');

    const [totalCollect, setTotalCollect] = useState(0);
    const [cash, setCash] = useState(0);
    const [cheque, setCheque] = useState(0);
    const [card, setCard] = useState(0);

    const fetchSalesRapOptions = async () => {
        const apiSalesRapUrl = `${API_URL}/getSalesRep_or_driver`;
        try {
            const response = await fetch(apiSalesRapUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                }
            });

            const data = await response.json();

            const formattedOptions = data.data.map(item => ({
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
            }));

            setUserOptions(formattedOptions);
            setUserFilteredOptions(formattedOptions);

        } catch (error) {
            console.error('api fail Error fetching Not Order Report:', error);
        }
    };

    const userHandleChange = (selectedOption) => {
        if (selectedOption) {
            setUserValue(selectedOption);
        } else {
            setUserValue(null);
        }
    };
   const handleGeneratePDF = () => {
        generatePaymentLedgerPDF(paymentDetails, paymentLedger);
    };
    const userHandleInputChange = (inputValue) => {
        setUserInputValue(inputValue);
        const newFilteredOptions = userOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setUserFilteredOptions(newFilteredOptions);
    };

    const searchFormData = () => {
        let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
        let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
        setReportrange(startDate ? startDate + ' - ' + endDate : '');

        let salesRapId = userValue ? userValue.value : '';

        if (!salesRapId) {
            setFlashMessage({ message: 'Please select customer', type: 'error' });
            return;
        }
        loadPaymentLadger(salesRapId, startDate, endDate);
    };

    const loadPaymentLadger = async (salesRapId, startDate, endDate) => {
        try {
            const data = await AccountService.fetchPaymentLadger(salesRapId, startDate, endDate, accessToken);
            if (Array.isArray(data.data) && data.data.length === 0) {
                setPaymentDetails({ name: '', startDate: '', endDate: '' });
                setPaymentLedger([]);
                setNodataRow('showinfo');
                return;
            }
            if (data.data.customer_credit.id) {
                setPaymentDetails({
                    name: data.data.customer_credit.first_name + ' ' + data.data.customer_credit.last_name,
                    startDate: data.start_date,
                    endDate: data.end_date
                });
                setPaymentLedger(data.data.customer_ledger);
                setNodataRow('');
                console.log(data);
            }
        } catch (error) {
            console.log('Failed to load Payment ledger', error);
        }
    };

    const [flashMessage, setFlashMessage] = useState(null);
    const handleCloseMessage = () => {
        setFlashMessage(null);
    };

    useEffect(() => {
        if (accessToken === null) {
            window.location.href = `${config.BASE_URL}/login`;
            return;
        }
        fetchSalesRapOptions();
    }, []);

    useEffect(() => {
        let total = 0;
        let cashTotal = 0;
        let chequeTotal = 0;
        let cardTotal = 0;

        paymentLedger.forEach(item => {
            const formattedAmount = parseFloat(item.collected_amount) || 0;
            total += formattedAmount;

            if (item.payment_mode === "cash") cashTotal += formattedAmount;
            else if (item.payment_mode === "card") cardTotal += formattedAmount;
            else if (item.payment_mode === "cheque") chequeTotal += formattedAmount;
        });

        setTotalCollect(total);
        setCash(cashTotal);
        setCheque(chequeTotal);
        setCard(cardTotal);
    }, [paymentLedger]);

    const renderRow = (item) => {
        const {
            date,
            name,
            collected_amount,
            payment_mode,
            account_no,
        } = item;
        const formattedAmount = parseFloat(collected_amount).toFixed(2);
        const formattedPaymentMode = payment_mode ? payment_mode : '';
        const formattedDate = date ? date : '';
        const formattedCompany = name ? name : '';
        const formattedaccount_no = account_no ? account_no : '';

        return (
            <tr>
                <td>{formattedDate}</td>
                <td>{formattedCompany} ({formattedaccount_no})</td>
                <td style={{ textAlign: 'right' }}>{formattedAmount}</td>
                <td style={{ textAlign: 'right' }}>{formattedPaymentMode}</td>
            </tr>
        );
    };

    return (
        <>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <Link to="/Dashboard"><i className="fa fa-home"></i></Link>
                            <Link to="/account/payment_ladger">Account</Link>
                            <Link to="/account/payment_ladger">Payment Ledger</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container-fluid">
                    <div className="total-manifests-area dashboard" style={{ position: 'relative' }} >
                        <p><b>Payment Ledger </b></p>
                        {paymentDetails.name && (
                            <div>
                            <button onClick={handleGeneratePDF} className="noopener acceptbtn">
                                    Print
                                </button>
                             
                            </div>
                        )}

                        <div className="row">
                            <div className="col-md-3 cp-lg-3 col-sm-3" style={{ float: 'left', paddingLeft: '20px' }}>
                                <Select className='abovedd'
                                    isClearable
                                    onInputChange={userHandleInputChange}
                                    onChange={userHandleChange}
                                    options={userFilteredOptions}
                                    placeholder="Select User"
                                    value={userValue}
                                />
                            </div>

                            <div className="col-md-9 col-lg-9 col-sm-12">
                                <CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
                            </div>
                        </div>

                        {paymentDetails.name && (
                            <div className="row customerInfo">
                                <div className="col-md-8 col-lg-8 col-sm-12">
                                    <div className="common-table autoheight-table" id="style-3">
                                        <p style={{ textAlign: 'left', margin: '0px', border: 'none' }}><b>From Date : </b> {paymentDetails.startDate} To : {paymentDetails.endDate}</p>
                                        <table className="table table-bordered dataTable resizable">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '35%' }}>
                                                        <b style={{ fontWeight: '800' }}>
                                                            Total Collected: {totalCollect.toFixed(2)} |
                                                            Cash: {cash.toFixed(2)} |
                                                            Cheque: {cheque.toFixed(2)} |
                                                            Card: {card.toFixed(2)}
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {paymentLedger.length > 0 && (
                            <div className="row customerInfo">
                                <div className="col-md-9 col-lg-9 col-sm-12">
                                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
                                        <table className="table table-bordered dataTable resizable">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Customer</th>
                                                    <th>Amount</th>
                                                    <th>Payment Mode</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paymentLedger.map((item) => renderRow(item))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {nodataRow && (
                            <div className="row text-center">
                                <div className="col-md-8 col-lg-8 col-sm-12" style={{ paddingTop: '15px' }}>
                                    <p className="text-danger">NO Transaction Available.</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <FlashMessage
                message={flashMessage?.message}
                type={flashMessage?.type}
                onClose={handleCloseMessage}
            />
            <Footer />
        </>
    );
}

export default PaymentLadger;