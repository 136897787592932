import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import generatePickerListPDF from './PickerListPDF';

const ViewPickingList = () => {

	const { picklistNo } = useParams();
	const accessToken = localStorage.getItem('accessToken');
	const firstName = localStorage.getItem('first_name');
	const lastName = localStorage.getItem('last_name');
	const [showCustomer, setShowCustomer] = useState(false);
	const todayDate = new Date();

	function formatDate(date) {
		const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
		const year = date.getFullYear(); // Get full year

		return `${day}-${month}-${year}`; // Return formatted date
	}

	function formatNewDate(dateString) {

		if (dateString == '0000-00-00') {
			return ``;
		} else {
			const date = new Date(dateString); // Parse the date string
			const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
			const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
			const year = date.getFullYear(); // Get full year

			return `${day}-${month}-${year}`; // Return formatted date
		}
	}

	const [headerData, setHeaderData] = useState({
		customerName: '',
		orderNumber: '',
		accountNumber: '',
		routeNumber: '',
		pickingList: '',
		deliveryDate: '',
		printBy: '',
		printDate: '',
		shop_name: '',
		address: '',
		phone: '',
		email: '',
		website: '',
	});
	const [pickList, setPickList] = useState([]);
	const [pickComment, setPickComment] = useState([]);
	const [totalQty, setTotalQty] = useState([]);

	const handleGeneratePickerListPDF = () => {
		generatePickerListPDF(picklistNo);
	}

	const fetchViewPickerList = async () => {
		const formData = new FormData();
		formData.set('picklist_number', picklistNo);

		const apiUrl = `${config.API_URL}/get_view_picklist`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Picker List');
			}

			const data = await response.json();
			// alert(data.data.reference_no.length);
			if (data.data.reference_no.length == 1) {
				setShowCustomer(true);
			}
			const referenceNos = data.data.reference_no
				.map(item => item.reference_no)
				.join(', ');
			const acNos = data.data.reference_no
				.map(item => item.accound_no)
				.join(', ');
			const rnNos = data.data.reference_no
				.map(item => item.route_number)
				.join(', ');

			setHeaderData({
				customerName: data.data.reference_no[0].customer,
				orderNumber: referenceNos,
				accountNumber: acNos,
				routeNumber: rnNos,
				pickingList: data.data.picklist_number,
				deliveryDate: data.data.reference_no[0].deliverydate,
				printBy: data.data.reference_no[0].print_by,
				printDate: data.data.reference_no[0].print_on,
				shop_name: data.data.shop_details.shop_name,
				address: data.data.shop_details.address,
				phone: data.data.shop_details.phone,
				email: data.data.shop_details.email,
				website: data.data.shop_details.website,
			});
			setPickList(data.data.packing_details);
			setPickComment(data.data.accound_no);

			let tQty = data.data.packing_details.reduce((acc, product) => acc + parseInt(product.quantity), 0);
			setTotalQty(tQty);

		} catch (error) {
			console.error('Error fetching Picker List data:', error);
		}



	};

	useEffect(() => {

		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}

		fetchViewPickerList();
	}, []);

	return (
		<>
			<Header />

			<div class="breadcrumbs">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span class="last-crumb">Picking List :- {picklistNo}</span>
						</div>
					</div>
				</div>
			</div>

			<div className="main-container">
			<div className="container-fluid">

			<div className="row">
				<div className="col-12">

					<button onClick={(e) => handleGeneratePickerListPDF()} className="acceptbtn">
						Print Picking List
					</button>
				</div>
			</div>
			<div className="row">
				<div className="col-12">

					<div className="show-order-area dashboard">

						<div className="sales-view-area">
							<header>
								<div class="row align-items-center gy-3">
									<div class="col-sm-4 text-center text-sm-start"> <img src={`${config.BASE_URL}/assets/img/${accessToken}_logo.png`} width={accessToken === 'knm' || accessToken === 'eve' || accessToken === 've' ? '30%' : ''} alt="Logo" />
									</div>
									<div class="col-sm-8">

										<table width="100%" border="0" cellspacing="0" cellpadding="0">
											<tbody>
												<tr>
													<td><h4 class="text-7 mb-0">{headerData.shop_name}</h4></td>
												</tr>
												<tr>
													<td>
														<address>
															{headerData.address}
														</address>
													</td>
												</tr>
												<tr>
													<td><b>Phone No :</b> {headerData.phone} | <b>Email :</b> {headerData.email}</td>
												</tr>
												<tr>
													<td><b>Website :</b> {headerData.website}</td>
												</tr>
											</tbody>
										</table>

									</div>
								</div>
								<hr />
							</header>

							<div style={{ marginBottom: '0px' }} >
								<div class="col-12 col-lg-12 col-sm-12">
									<table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
										<tbody>
											<tr>
												<td style={{ paddingRight: '10px', width: '33%' }}>
													<div class="col-12 col-lg-12 col-sm-12 order-sm-1" style={{ verticalAlign: 'top' }}>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															{showCustomer && (
																<tr>
																	<td style={{ verticalAlign: 'top', width: '22%' }}><strong>Customer Name :</strong></td>
																	<td style={{ textAlign: 'left' }}>{headerData.customerName}</td>
																</tr>
															)}
															<tr>
																<td style={{ verticalAlign: 'top', width: '22%' }}><strong>Order Number :</strong> </td>
																<td style={{ whiteSpace: 'normal' }}> {headerData.orderNumber}</td>
															</tr>
															<tr>
																<td style={{ verticalAlign: 'top', width: '22%' }}><strong>Account Number :</strong> </td>
																<td style={{ whiteSpace: 'normal' }}> {headerData.accountNumber}</td>
															</tr>

															<tr>
																<td style={{ verticalAlign: 'top', width: '22%' }}><strong>Route Number :</strong> </td>
																<td style={{ whiteSpace: 'normal' }}> {headerData.routeNumber}</td>
															</tr>
														</table>
													</div>
												</td>
												<td style={{ paddingRight: '10px', width: '33%' }}>
													<div class="col-12 col-lg-12 col-sm-12 order-sm-0">
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td style={{ width: '20%' }}><strong>Picking List :</strong> </td>
																<td><strong>{headerData.pickingList}</strong></td>
															</tr>

															<tr>
																<td><strong>Delivery Date :</strong> </td>
																<td>{formatNewDate(headerData.deliveryDate)}</td>
															</tr>

															<tr>
																<td><strong>Print By : </strong> </td>
																<td> {firstName} {lastName}</td>
															</tr>
															<tr>
																<td><strong>Print Date : </strong> </td>
																<td> {formatDate(todayDate)}</td>
															</tr>

														</table>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div className="verical-scrl-wrapper picking-list-invoice-tbl" id="style-3">
								<table className="table table-bordered resizable dataTable" id="purchaseProforma">
									<thead>
										<tr>
											<th>Product Code</th>
											<th>Product Name</th>
											<th>Product Type</th>
											<th>Quantity</th>
										</tr>
									</thead>
									<tbody>
										{pickList.map((item, index) => {

											const isDiscount100 = parseFloat(item.discount || 0) === 100;

											return (
												<tr key={item.code} >
													<td style={{ color: isDiscount100 ? 'red' : 'inherit' }}>{item.code}</td>
													<td style={{ color: isDiscount100 ? 'red' : 'inherit' }}>{item.name}</td>
													<td style={{ color: isDiscount100 ? 'red' : 'inherit' }}>{item.order_type}</td>
													<td style={{ color: isDiscount100 ? 'red' : 'inherit' }}>{parseInt(item.quantity)}</td>
												</tr>
											);
										})}
									</tbody>
									<tfoot>
										<tr>


											<td colSpan={2}><span></span></td>


											<td><span>Total</span></td>
											<td><span>{totalQty}</span></td>
										</tr>
									</tfoot>
								</table>
							</div>

							<div className="sales-view-table">
								<table className="table table-bordered resizable dataTable" id="purchaseProforma">
									<thead>
										<tr>
											<th>A/c No</th>
											<th>Comment</th>
											<th>Comment By</th>
											</tr>
									</thead>
									<tbody>
										{pickComment?.map((item) => {
											const rows = [];

											// Check for staff_note
											if (item.staff_note) {
												rows.push(
													<tr key={`${item.id}-staff-note`}>
														<td>{item.accound_no}</td>
														<td>{item.staff_note}</td>
														<td>{item.name}</td>
													</tr>
												);
											}

											// Check for note
											if (item.note) {
												rows.push(
													<tr key={`${item.id}-note`}>
														<td>{item.accound_no}</td>
														<td>{item.note}</td>
														<td>{item.name}</td>
													</tr>
												);
											}

											return rows;
										})}

									</tbody>
								</table>
							</div>


						</div>
					</div>
				</div>
			</div>

</div>
</div>

			<Footer />

		</>

	);
}
export default ViewPickingList;