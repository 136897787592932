import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Brands = () => {
  
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [brandList, setBrandList] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [brandFormData, setBrandFormData] = useState({
    code: '',
    name: '',
    slug: '',
    description: '',
  });

  const [brandEditData, setBrandEditData] = useState({
    code: '',
    name: '',
    slug: '',
    description: '',
    bid : '',
  });


  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setBrandFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleBrandSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('brand_code', brandFormData.code);
    formData.append('brand_name', brandFormData.name);
    formData.append('slug', brandFormData.slug);
    formData.append('description', brandFormData.description);



    const apiBrandUrl = `${config.API_URL}/add_product_brand`; 
    try {
      const response = await fetch(apiBrandUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add brand');
      }

      const data = await response.json();
 
      const msg = data.message;
      if(data.success){

        Swal.fire({
          title: 'Success',
          text: 'Brand added successfully.',
          icon: 'success', 
          confirmButton: false, 
          timer: 1500,
          showConfirmButton: false, 
        });
      }else{

        Swal.fire({
          title: 'Failed',
          text: msg,
          icon: 'error', 
          confirmButton: false,
          timer: 1500,
          showConfirmButton: false,  
        });
      }
      


      setBrandFormData({
        code: '',
        name: '',
        slug: '',
        description: '',
      });

      fetchBrandList();


      
    } catch (error) {
      console.error('Error adding brand:', error);
    }

  };

  const handleEditClick = (rowData) => {
    setBrandEditData({
      code: rowData.code,
      name: rowData.name,
      slug: rowData.slug,
      description: rowData.description,
      bid : rowData.id,
    });
  };

  const handleFormEditChange = (e) => {
    const { name, value } = e.target;
    setBrandEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBrandUpdate = async(event) =>{
    event.preventDefault();

    const formData = new FormData();
    formData.append('brand_code', brandEditData.code);
    formData.append('brand_name', brandEditData.name);
    // formData.append('slug', brandEditData.slug);
    formData.append('description', brandEditData.description);
    formData.append('bid', brandEditData.bid);



    const apiBrandEditUrl = `${config.API_URL}/edit_product_brand`; 
    try {
      const response = await fetch(apiBrandEditUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update brands');
      }

      const data = await response.json();
      

      const msg = data.message;
      if(data.success){

        Swal.fire({
          title: 'Success',
          text: 'brands updated successfully.',
          icon: 'success', 
          confirmButton: false, 
          timer: 1500,
          showConfirmButton: false, 
        });
      }else{

        Swal.fire({
          title: 'Failed',
          text: msg,
          icon: 'error', 
          confirmButton: false,
          timer: 1500,
          showConfirmButton: false,  
        });
      }
      
      setBrandEditData({
        code: '',
        name: '',
        // slug: '',
        description: '',
        bid : '',
      });

      fetchBrandList();
      
    } catch (error) {
      console.error('Error updated brands:', error);
    }
  };

  const fetchBrandList = async () =>{
    const brandForm = new FormData();

    brandForm.append("draw", draw);
    brandForm.append("start", start);
    brandForm.append("length", length);

    const apiBrandUrl = `${config.API_URL}/get_product_brand`;
    try{
      const response = await fetch(apiBrandUrl, {
        method : 'POST',
        headers : {
          authorization : `Bearer ${accessToken}`,
        },
        body : brandForm,
      });

      if(!response.ok){
        throw new Error("Failed to fetch brand list");
      }

      const data = await response.json();

      console.log(data);
      setBrandList(data.data);

    }catch (error){
      console.log("Error fetch brand list", error);
    }

  };

  useEffect(() => {
    if (accessToken === null) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
    }

    fetchBrandList();
  }, [accessToken]);


  const handleChangeStatus = async(id, status)=>{
    const formData = new FormData();
    
    formData.append('status', status);
    formData.append('bid', id);



    const apiUrl = `${config.API_URL}/update_brand_status`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to change status');
      }

      const data = await response.json();
      console.log(data);
      
      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success', 
        showConfirmButton: false, 
        timer: 1500,  
      });
      
    } catch (error) {
      console.error('Error status change:', error);
    }
  }


  useEffect(() => {
      const table = $('#brandListTable').DataTable({
          pageLength: 10,
          paging: true,
          lengthMenu: [10, 20, 50],
          searching: true,
          ordering: false,
          info: true,
          responsive: true,
          autoWidth: false,
          language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
          },
          data: brandList,
          columns: [
            {
                data: null,
                className : 'text-center',
                width : '4%',
                render: function(data, type, row, meta) {
                    return meta.row + meta.settings._iDisplayStart + 1;
                }
            },
            { data: 'code', className : 'text-start', width : '10%' },
            { data: 'name', className : 'text-start'},
            { data: 'slug', className : 'text-start', width : '14%' },
            { 
              data: 'code', 
              className : 'text-center',
              width : '10%',
              render : function(data, type, row){
                const checked = row.status == "1" ? "checked" : "";
                return `
                    <label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
              } 
            },
            { 
              data: 'code', 
              className : 'text-center',
              width : '10%',
              render : function (data, type, row){
                
                return `
                   <a href="#" class="icon edit-icon" data-id="${row.id}" title="Edit" data-bs-toggle="modal" data-bs-target="#edit-brand">
                    <i class="fa fa-pencil"></i>`;
              } 
            }

            
          ],
          columnDefs: [
             
          ]
      });

      $('#brandListTable').off('change', '.switch-input').on('change', '.switch-input', function(event) {
        const id = $(this).data('id');
        const status = this.checked ? 1 : 0;

        handleChangeStatus(id, status);
      });

      $('#brandListTable').off('click', '.edit-icon').on('click', '.edit-icon', function(event) {
        const rowId = $(this).data('id'); 
        const rowData = table.row($(this).closest('tr')).data();
      
        if (rowData && Object.keys(rowData).length > 0) {
          console.log(rowData);
          handleEditClick(rowData);
        } 
      });

      return () => {
        table.destroy();
      };
  }, [brandList]);
 

  return (
    <div>
      <div class="modal fade" id="add-brand" tabindex="-1">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add Brand</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="popup-area">
                <form id="addNewBrand" onSubmit={handleBrandSubmit}>
                  <div class="row mb-3 padd-l-13px">
                    <label for="code" class="col-sm-4 col-form-label">Code <span className='red'>*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="code" id="code" value={brandFormData.code} onChange={handleFormChange} required  />
                    </div>
                  </div>

                  <div class="row mb-3 padd-l-13px">
                    <label for="name" class="col-sm-4 col-form-label">Name <span className='red'>*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="name" id="name" value={brandFormData.name} onChange={handleFormChange} required />
                    </div>
                  </div>

                  {/* <div class="row mb-3 padd-l-13px">
                    <label for="slug" class="col-sm-4 col-form-label">Slug <span className='red'>*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="slug" id="slug" value={brandFormData.slug} onChange={handleFormChange} required />
                    </div>
                  </div> */}

                  <div class="row mb-3 padd-l-13px">
                    <label for="description" class="col-sm-4 col-form-label">Description</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="description" id="description" value={brandFormData.description} onChange={handleFormChange} />
                    </div>
                  </div>


                  <hr />
                  <div class="row padd-l-13px">
                    <div class="col-sm-12">
                      <button type="submit" data-bs-dismiss="modal" aria-label="Close" id="submit" class="float-right-btn common-popup-btn">Add Brand</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="edit-brand" tabindex="-1">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Brand</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="popup-area">
                
                <form id="editBrand" onSubmit={handleBrandUpdate}>
                  <input type="hidden" value={brandEditData.bid} />
                  <div class="row mb-3 padd-l-13px">
                    <label for="editCode" class="col-sm-4 col-form-label">Code <span className='red'>*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="code" id="editCode" value={brandEditData.code}  onChange={handleFormEditChange} required />
                    </div>
                  </div>

                  <div class="row mb-3 padd-l-13px">
                    <label for="editName" class="col-sm-4 col-form-label">Name <span className='red'>*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="name" id="editName" value={brandEditData.name}  onChange={handleFormEditChange} required />
                    </div>
                  </div>

                  {/* <div class="row mb-3 padd-l-13px">
                    <label for="editSlug" class="col-sm-4 col-form-label">Slug <span className='red'>*</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="slug" id="editSlug" value={brandEditData.slug}  onChange={handleFormEditChange} required />
                    </div>
                  </div> */}

                  <div class="row mb-3 padd-l-13px">
                    <label for="editDescription" class="col-sm-4 col-form-label">Description</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="description" id="editDescription" value={brandEditData.description}  onChange={handleFormEditChange}   />
                    </div>
                  </div>

                  

                  <hr />
                  <div class="row padd-l-13px">
                    <div class="col-sm-12">
                      <button type="submit" data-bs-dismiss="modal" aria-label="Close" id="submitEdit" class="float-right-btn common-popup-btn">Edit Brand</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#"><i class="fa fa-home"></i></a> &gt; 
              <a href="Dashboard">Master</a> &gt; 
              <span class="last-crumb">Brands</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: 'relative' }}>
                <div class="row">
                  <div class="col-12 col-lg-4 col-sm-4">
                    {/* Add any additional filters here */}
                  </div>
                  <div class="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right"}}>
                    <a class="create-catalogue-btn" href="#" style={{ marginLeft: '10px'}}>Export to Excel</a>
                    <a class="create-catalogue-btn" href="#" data-bs-toggle="modal" data-bs-target="#add-brand" style={{ marginLeft: '10px'}}>Add Brands</a>
                      {/* <div class="dropdown">
                        <button class="action-btn-dd dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> Action </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#add-brand">Add Brands</a></li>
                          <li><a class="dropdown-item" href="#">Export to Excel</a></li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="show-order-area dashboard" >
                  <div className="verical-scrl-wrapper common-table brand-table" id="style-3">
                    <table className="table table-bordered resizable" id="brandListTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Code</th>
                          <th width="300px">Name</th>
                          <th width="150px">Slug</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from({ length: 2 }).map((_, index) => (
                          <tr key={index}>
                            <td style={{width: '50px'}}>
                              <label class="checkbox">
                                <input type="checkbox" />
                                <span class="default"></span>
                              </label>
                            </td>
                            
                            <td>WSHORTBREAD</td>
                            <td>Walkers Shortbread</td>
                            <td>Walkers Shortbread</td>
                            <td>
                              <a href="#"><span class="label label-success"><i class="fa fa-check"></i> Active</span></a>
                            </td>
                            <td style={{width: '120px'}}>
                              <a href="#" class="icon" title="Edit" data-bs-toggle="modal" data-bs-target="#edit-brand"><i class="fa fa-pencil"></i></a>
                              <a href="#" class="icon" title="Delete"><i class="fa fa-trash"></i></a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>

  );
};

export default Brands; 