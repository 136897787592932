import React, { useState, useEffect, useRef, useCallback  } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import FlashMessage from './FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import debounce from 'lodash.debounce';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";
import Swal from 'sweetalert2';

const OpenTrips = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [openTrips, setOpenTrips] = useState([]); // State to store statistics data
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const [options, setOptions] = useState([]);
  const [vehicles, setSelectedVehicles] = useState('');

  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  let [tripid, setTripid] = useState('');

  const [flashMessage, setFlashMessage] = useState(null);
  const accessToken = localStorage.getItem('accessToken');
useEffect(() => {
  async function fetchVehicleOptions() {
    try {
      // Correctly pass headers as the third argument
      const response = await axios.post(
        `${config.API_URL}/get-vehicles_web`, 
        {}, // The request body (empty here, add if needed)
        {
          headers: {
            authorization: `Bearer ${accessToken}`, // Add Authorization header here
          },
        }
      );

      // Validate response format
      if (!response.data || !response.data.data || !Array.isArray(response.data.data)) {
        throw new Error('Invalid response format or empty vehicle array');
      }

      // Format the options
      const formattedOptions = response.data.data.map(item => ({
        key: `${item.modelName}`,
        value: item.vehicleId,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching vehicle options:', error);
      // Handle error as needed
    }
  }

  fetchVehicleOptions();
}, []);


   const updateVehicleInfo = async () => {
    let vehiclesID = `${vehicles}`;
    //console.log(vehiclesID + '---' + tripid);
    if(!vehiclesID){
     

      Swal.fire({
        title: 'Error',
        text: 'Please Select order.',
        icon: 'error',
        showConfirmButton: false, 
        timer: 2000,               
        timerProgressBar: true,     
      });
    }else{
      const formOrder = new FormData();
      formOrder.append('tripId', tripid);
      formOrder.append('vehicle_id', vehiclesID);
      try {
        const res = await fetch(`${config.API_URL}/update_trip_vehicle`, {
          method: 'POST',
          headers: {
           authorization: `Bearer ${accessToken}`,
          },
          body: formOrder,
        });
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await res.json();
        fetchData();
        Swal.fire({
          title: 'Success',
          text: 'vehicle updated successful!.',
          icon: 'success',
          showConfirmButton: false, 
          timer: 2000,               
          timerProgressBar: true,     
        });

        

        //setFlashMessage({ message: 'vehicle updated successful!', type: 'success' });
      } catch (err) {
        console.log(err.message);
      }


    }
  };


  const handleCloseMessage = () => {
    setFlashMessage(null);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `${config.API_URL}/get_trip_list`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setOpenTrips(data.data);
      //console.log(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  const viewVechMdl = (userId) => {
    setTripid(userId);
    if (modalRef.current) {
      const modal = new window.bootstrap.Modal(modalRef.current);
      modal.show();
    }
  };


  $(document).off('click', '#openTripsTable .mdlBtn').on('click', '#openTripsTable .mdlBtn', function() {
    const userId = $(this).data('id');
   
    viewVechMdl(userId);
  });

  useEffect(() => {
    const table = $('#openTripsTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
      search: 'Search:',
      },
      data: openTrips,
      columns: [
        { data: null },
        { data: 'date' },
        { 
            data: 'trip_number',
            render: function (data, type, row) {
                return '<a href="'+BASE_URL+'/open_trips/trip_summary/' + row.id + '">' + row.trip_number + '</a>';
            }
          
        },
        { data: 'driver' },

        { data: 'vehicle', className: "text-center" },
        { data: 'route_number' },
        { data: 'status', className: "text-center" },
        { 
            data: null,
            render: function (data, type, row) {
                //return '<button class="btn btn-primary btn-sm" onClick={viewVechMdl(row.id)}  >Change Vehicle</button>';

                return `<button class="change-vehicl-btn mdlBtn" data-id="${row.id}">Change Vehicle</button>`;
            }

        }
    ],
    columnDefs: [
        { 
            targets: [0, 1, 2, 3, 4, 5,6], 
            orderable: false 
        }
    ],
    drawCallback: function (settings) {
        var api = this.api();
        var startIndex = api.page.info().start;
        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
            cell.innerHTML = startIndex + i + 1;
        });
    }
    });
    return () => {
      table.destroy();
    };
  }, [openTrips]);

  

  return (
    <div>
      <Header />

      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a>
              <span className="last-crumb">Open Trips</span>
            </div>
          </div>
        </div>
      </nav>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="dashboard">
               



                <div className="row">
                 <div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
								<div class="row mb-3 padd-0">
									<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>From</b></label>
									<div class="col-sm-9">
                  <input
                        type="date"
                        name="fromDate"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="form-control"
                      />
									</div>
								</div>
							</div>


							<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left'}}>

								<div class="row mb-3 padd-0">
									<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>To</b></label>
									<div class="col-sm-9">
                  <input
                        type="date"
                        name="toDate"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className="form-control"
                      />
									</div>
								</div>
							</div>

							
							<div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
								<button type="button" onClick={handleSubmit} className="acceptbtn" style={{ float: 'left' }} >Submit</button>
							</div>

                 

                </div>


                
                <div class="total-manifests-area dashboard">
                  <div className="verical-scrl-wrapper open-trip" id="style-3">
                    <table className="table table-bordered dataTable resizable" id="openTripsTable">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Date</th>
                          <th>Trip ID</th>
                          <th>Driver</th>
                          <th>Vehicle</th>
                          <th>Route</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      </tbody>
                    </table>
                  </div>

                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div ref={modalRef} className="modal fade" id="vehicleMdl" aria-hidden="true" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Vehicle</h5>
                <button type="button" className="btn-close" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <select value={vehicles} onChange={(e) => setSelectedVehicles(e.target.value)} className="datatable-selector form-control" name="driver_list" id="driver_list"  style={{marginRight: "15px"}} >
                    <option value="">Select a vehicles</option>
                      {options.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                      ))}
                </select>
                
              </div>
              <div className="modal-footer">
                <button type="button" className="common-closebtn" data-bs-dismiss="modal" >Close</button>
                <button type="button" className="float-right-btn common-popup-btn" data-bs-dismiss="modal" onClick={updateVehicleInfo}  >Submit changes</button>
              </div>
            </div>
          </div>
        </div>
        <FlashMessage
          message={flashMessage?.message}
          type={flashMessage?.type}
          onClose={handleCloseMessage}
        />
    </div>
  );
};

export default OpenTrips;
